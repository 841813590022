import { Content, Datapoints, Media, MediaObject } from '@fwl/shared'

const SurveyOverview = ({ children, badge, ...props }) => {
  return (
    <MediaObject {...props}>
      <Media>{badge}</Media>
      <Datapoints as={Content}>{children}</Datapoints>
    </MediaObject>
  )
}

export default SurveyOverview
