import React from 'react'

import styled from '@emotion/styled'

import { BREAKPOINTS, isNil, mq, Panel } from '@fwl/shared'

import { useStore } from '../useStore'

import AreaScore from './AreaScore'
import ParticipationDonut from './ParticipationDonut'
import Sparkline from './Sparkline'
import Trend from './Trend'

const StyledPulseScoreSummary = styled.div`
  display: grid;
  grid-gap: 2rem;
  margin-bottom: 2rem;

  ${mq(BREAKPOINTS.sm)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mq(BREAKPOINTS.md)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mq(BREAKPOINTS.xl)} {
    grid-template-columns: repeat(4, 1fr);
  }

  > * {
    min-width: 0;
  }

  .participation-card {
    .title {
      font-weight: ${({ theme }) => theme.typography.display.medium};
      font-family: ${({ theme }) => theme.typography.display.family};
      font-size: 1.1rem;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }

  .footer {
    justify-items: center;
    margin-top: 0.5rem;
    text-align: center;
    display: grid;
    grid-gap: 1rem;
    align-items: center;
`

const PulseScoreSummary = ({
  pulse,
  currentIteration,
  team = null,
  ...props
}) => {
  const areas = useStore((state) => state.areas)

  const trend =
    team === null
      ? pulse.iterations.map(
          ({
            id,
            scores: { organization: scores },
            participation: { organization: participation },
          }) => ({ id, scores, participation })
        )
      : pulse.iterations.map(
          ({
            id,
            scores: {
              teams: { [team.id]: scores },
            },
            participation: {
              teams: { [team.id]: participation },
            },
          }) => ({ id, scores, participation })
        )

  const scores =
    team === null
      ? currentIteration.scores.organization
      : currentIteration.scores.teams[team.id]

  const participation =
    team === null
      ? currentIteration.participation.organization
      : currentIteration.participation.teams[team.id]

  console.log(trend)

  return (
    <StyledPulseScoreSummary className="" {...props}>
      {Object.entries(scores).map(([areaSlug, score]) => {
        const area = areas.find(({ slug }) => slug === areaSlug)
        const to =
          team === null
            ? `/organization/pulse/areas/${area.slug}`
            : `/teams/${team.id}/pulse/areas/${area.slug}`

        return (
          <AreaScore
            key={area.slug}
            area={area}
            score={score}
            to={to}
            trend={trend
              .map(({ scores: areaScores }) => areaScores?.[area.slug])
              .filter((value) => !isNil(value))}
          />
        )
      })}
      <Panel inverted>
        <div className="participation-card">
          <div className="title">Participation</div>
          <div className="donut">
            <ParticipationDonut
              answered={participation.answered}
              total={participation.total}
            />
            <div className="footer">
              <Trend
                trend={trend
                  .map(({ participation }) => participation?.percentage)
                  .filter((value) => !isNil(value))}
              />
              <Sparkline
                values={trend
                  .map(({ participation }) => participation?.percentage)
                  .filter((value) => !isNil(value))
                  .reverse()}
                style={{ height: '1.5rem', padding: '0.1rem 0' }}
              />
            </div>
          </div>
        </div>
      </Panel>
    </StyledPulseScoreSummary>
  )
}

PulseScoreSummary.displayName = 'PulseScoreSummary'

export default PulseScoreSummary
