import React from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { Button } from '@fwl/shared'

const LaunchSurvey = styled(Button)`
  margin-top: 0.5rem;
  background: ${({ theme }) => theme.palette.teal.base};
  color: ${({ theme }) => theme.palette.teal.contrast};
  font-weight: ${({ theme }) => theme.typography.normal.bold};
  border: 0;

  .components--icon {
    margin-right: 0.3rem;
  }
`

const SurveyCTA = ({ to, ...props }) => (
  <LaunchSurvey
    as={Link}
    to={to}
    appearance="dark"
    size="large"
    block
    {...props}
  >
    <svg
      className="components--icon"
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      viewBox="0 0 24 24"
      width="20px"
      fill="currentColor"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
    </svg>
    Launch survey
  </LaunchSurvey>
)

SurveyCTA.displayName = 'LaunchSurvey'

export default SurveyCTA
