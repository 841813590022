import React from 'react'

import styled from '@emotion/styled'

import { Title } from '@fwl/shared'

import { useStore } from '../useStore'
import { partitionAreas } from '../utils'

import AreaScore from './AreaScore'

const StyledSurveyScoreSummary = styled.div`
  .areas {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(235px - 1.5rem), 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
  }
`

const SurveyScoreSummary = ({ survey, scores, team = null, ...props }) => {
  const areas = useStore((state) => state.areas)
  const partitioned = partitionAreas(areas, scores)

  return (
    <StyledSurveyScoreSummary
      className="components--survey-score-summary"
      {...props}
    >
      {Object.entries(partitioned).map(([parentId, areaScores]) => {
        const title =
          parentId === null
            ? 'Unsorted'
            : areas.find(({ id }) => id === parseInt(parentId, 10)).title
        return (
          <div key={parentId} className="parent-section">
            <Title title={title} />
            <div className="areas">
              {areaScores.map(([area, score]) => {
                const to =
                  team === null
                    ? `/organization/surveys/${survey.id}/areas/${area.slug}`
                    : `/teams/${team.id}/surveys/${survey.id}/areas/${area.slug}`
                return (
                  <AreaScore
                    to={to}
                    key={area.slug}
                    area={area}
                    score={score}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </StyledSurveyScoreSummary>
  )
}

SurveyScoreSummary.displayName = 'PulseScoreSummary'

export default SurveyScoreSummary
