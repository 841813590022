import React from 'react'

import styled from '@emotion/styled'

import { Icon, MenuLink, Sidebar as SidebarWrapper, useAuth } from '@fwl/shared'

import { useStore } from '../useStore'

import SurveyCTA from './SurveyCTA'

const Header = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.primary.fade1};
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  display: flex;
  align-items: center;

  .mark {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 0.5rem;
    background: ${({ theme }) => theme.primary.shade2};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    flex-shrink: 0;

    img {
      width: 80%;
      height: 80%;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  .company {
    min-width: 0;

    .name {
      font-size: 1.15rem;
      font-weight: ${({ theme }) => theme.typography.display.bold};
      max-width: 100%;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .workspace {
      font-size: 0.85rem;
      opacity: 0.6;
    }
  }

  a {
    margin-left: auto;
    color: inherit;

    svg {
    }
  }
`

const Sidebar = ({ ...props }, ref) => {
  const { profile, logOut } = useAuth()
  const account = useStore((state) => state.account)

  if (account === null) {
    return null
  }

  return (
    <SidebarWrapper
      {...props}
      profile={profile}
      account={account}
      logOut={logOut}
      footer={
        <>
          <SurveyCTA to="/launch" />
          <div className="divider" />
          <MenuLink to="/support">
            <Icon icon="support" />
            Support
          </MenuLink>
          <MenuLink to="/settings">
            <Icon icon="settings" />
            Settings
          </MenuLink>
        </>
      }
      ref={ref}
    >
      <Header>
        <div className="mark">
          {account.logo !== null && <img src={account.logo} />}
        </div>
        <div className="company">
          <div className="name">{account.name}</div>
          <div className="workspace">Workspace</div>
        </div>
      </Header>
      <MenuLink to="/" exact>
        <Icon icon="dashboard" />
        Overview
      </MenuLink>
      <div className="divider" />
      <MenuLink to="/organization">
        <Icon icon="poll" />
        Organization
      </MenuLink>
      <MenuLink to="/teams">
        <Icon icon="group-work" />
        Teams
      </MenuLink>
      <div className="divider" />
      <MenuLink to="/settings/members">
        <Icon icon="people" />
        Manage members
      </MenuLink>
    </SidebarWrapper>
  )
}

export default React.forwardRef(Sidebar)
