import React from 'react'

import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

const StyledLikertBars = styled.div`
  .participation {
    font-size: 0.9rem;
    margin-top: 1.5rem;
    text-align: center;
    opacity: 0.7;
  }
`

const LikertBars = ({
  question,
  participation,
  width,
  answers: allAnswers,
  ...props
}) => {
  const { choices, id } = question
  const theme = useTheme()

  const answers = allAnswers.reduce((acc, answerSet) => {
    if (id in answerSet) {
      return [...acc, answerSet[id]]
    }
    return acc
  }, [])

  const data = choices.map((choice, i) => {
    const choiceIndex = i + 1

    return {
      name: choice,
      votes: answers.filter((index) => parseInt(index, 10) === choiceIndex)
        .length,
    }
  })

  const { total } = participation
  const percentage =
    participation.total === 0 ? 0 : (answers.length / participation.total) * 100

  return (
    <StyledLikertBars className="component--likert-bars" {...props}>
      <ResponsiveContainer
        width={typeof width !== 'undefined' ? width : choices.length * 65}
        height={120}
      >
        <BarChart data={data} dataKey="votes" nameKey="name">
          <YAxis hide domain={[0, answers.length]} />
          <XAxis
            dataKey="name"
            ticks={choices}
            interval={0}
            axisLine={false}
            tickLine={false}
            tick={(p) => (
              <Text
                {...p}
                fontSize={12}
                width={10}
                textAnchor="middle"
                verticalAnchor="start"
              >
                {p.payload.value}
              </Text>
            )}
          />
          <Tooltip
            isAnimationActive={false}
            cursor={{ fill: 'transparent' }}
            formatter={(value, name) => [
              value ? `${((value / total) * 100).toFixed(2)}%` : '0%',
              name,
            ]}
            contentStyle={{
              background: '#fffe',
              fontSize: '0.9rem',
              border: 0,
              borderRadius: '0.2rem',
            }}
          />
          <Bar
            dataKey="votes"
            background={{ fill: '#f8f8f8', radius: [4, 4, 4, 4] }}
            barSize={40}
            radius={[2, 2, 2, 2]}
          >
            {choices.map((_, i) => (
              <Cell key={i} fill={theme.scale[i % theme.scale.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="participation">
        <em>n</em> = {total}, participation = {percentage.toFixed(2)}%
      </div>
    </StyledLikertBars>
  )
}

LikertBars.displayName = 'LikertBars'

export default LikertBars
