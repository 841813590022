import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  Button,
  ErrorMessage,
  Field,
  Input,
  Modal,
  Panel,
  Title,
  useFormContext,
  useRenderLocation,
} from '@fwl/shared'

import { useStore } from '../useStore'

const StyledContent = styled.div``

const CreateMemberView = () => {
  const store = useStore()
  const history = useHistory()
  const location = useRenderLocation()
  const [APIErrors, setAPIErrors] = useState([])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useFormContext()

  const redirect = () => {
    const redirectLocation =
      location.pathname === history.location.pathname
        ? { ...location, pathname: '/' }
        : location
    history.push({ ...redirectLocation, state: { preventScroll: true } })
  }

  const createMember = async (data) => {
    setAPIErrors([])
    try {
      const response = await store.createMember(data)
      if (response.nonFieldErrors) {
        setAPIErrors(response.nonFieldErrors)
      } else {
        redirect()
      }
    } catch (error) {
      console.dir(error)
    }
  }

  return (
    <Modal onClose={redirect}>
      <Panel>
        <StyledContent>
          <Title title="Create member" />
          <form onSubmit={handleSubmit(createMember)}>
            {APIErrors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
            <Field label="Full name">
              <Input
                placeholder="Enter full name ..."
                hasError={!!errors.name}
                {...register('name')}
              />
              <ErrorMessage errors={errors} fieldName="name" />
            </Field>
            <Field label="Department">
              <Input
                placeholder="Enter department ..."
                hasError={!!errors.department}
                {...register('department')}
              />
              <ErrorMessage errors={errors} fieldName="department" />
            </Field>
            <Field label="Location">
              <Input
                placeholder="Enter location ..."
                hasError={!!errors.location}
                {...register('location')}
              />
              <ErrorMessage errors={errors} fieldName="location" />
            </Field>
            <Field label="Email">
              <Input
                type="email"
                placeholder="Enter email ..."
                hasError={!!errors.email}
                {...register('email')}
              />
              <ErrorMessage errors={errors} fieldName="email" />
            </Field>
            <Button type="submit">Add member</Button>
          </form>
        </StyledContent>
      </Panel>
    </Modal>
  )
}

export default CreateMemberView
