import React from 'react'

import format from 'date-fns/format'

import { Button, Datapoint, Panel } from '@fwl/shared'

import { useStore } from '../useStore'

import SurveyOverview from './SurveyOverview'

const SurveyHeader = ({ surveyInstance, ...props }) => {
  const store = useStore()
  const { badges } = useStore((state) => state.account)

  return (
    <SurveyOverview
      as={Panel}
      badge={
        badges[surveyInstance.survey.badge] && (
          <img src={badges[surveyInstance.survey.badge]} />
        )
      }
      {...props}
    >
      <Datapoint label="Started at">
        {format(surveyInstance.startedAt, 'EEE, d MMM yyyy')}
      </Datapoint>

      {surveyInstance.endedAt === null && (
        <Datapoint label="Ends at">
          {surveyInstance.endsAt !== null && (
            <span style={{ marginRight: '0.6rem' }}>
              {format(surveyInstance.endsAt, 'EEE, d MMM yyyy')}
            </span>
          )}
          <Button
            size="slim"
            appearance="dark"
            onClick={() => store.stopSurveyInstance(surveyInstance.id)}
          >
            End now
          </Button>
        </Datapoint>
      )}

      {surveyInstance.endedAt !== null && (
        <Datapoint label="Ended at">
          {format(surveyInstance.endedAt, 'EEE, d MMM yyyy')}
        </Datapoint>
      )}

      <Datapoint label="Number of questions">
        {surveyInstance.survey.body.length}
      </Datapoint>

      <Datapoint label="Areas covered">
        {surveyInstance.survey.areas.length}
      </Datapoint>

      <Datapoint label="Participation">
        {surveyInstance.participation === null
          ? '-'
          : `${surveyInstance.participation.answered} of ${surveyInstance.participation.total} answered`}
      </Datapoint>
    </SurveyOverview>
  )
}

SurveyHeader.displayName = 'SurveyHeader'

export default SurveyHeader
