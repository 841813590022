import React from 'react'

import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

const StyledParticipationDonut = styled.div`
  position: relative;
  height: ${({ small }) => (small ? '8rem' : '10rem')};

  .score {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    font-weight: ${({ theme }) => theme.typography.normal.bold};
    opacity: 0.8;

    .percentage {
      font-size: 0.9rem;
    }
  }
`

const ParticipationDonut = ({ answered, total, ...props }) => {
  const theme = useTheme()

  const colors = [theme.palette.blue.base, '#00000011']
  const percentage = total === 0 ? 0 : (answered / total) * 100

  const data = [
    { name: 'answered', value: percentage },
    { name: 'unanswered', value: 100 - percentage },
  ]

  return (
    <StyledParticipationDonut
      className="component-participation-donut"
      {...props}
    >
      <ResponsiveContainer width="99%">
        <PieChart>
          <Pie
            isAnimationActive={false}
            startAngle={90}
            endAngle={-270}
            data={data}
            dataKey="value"
            nameKey="name"
            colors={colors}
            innerRadius={'66.5%'}
            outerRadius="80%"
            cornerRadius={2}
            stroke={0}
            margin={0}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="score">
        {percentage.toFixed(1)} <span className="percentage">%</span>
      </div>
    </StyledParticipationDonut>
  )
}

ParticipationDonut.displayName = 'ParticipationDonut'

export default ParticipationDonut
