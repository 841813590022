import React from 'react'

import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { getColor } from '../utils'

const StyledScoreDonut = styled.div`
  position: relative;
  height: ${({ small }) => (small ? '8rem' : '10rem')};

  .score {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: ${({ small }) => (small ? '1rem' : '1.3rem')};
    font-weight: ${({ theme }) => theme.typography.normal.bold};

    .of {
      font-size: ${({ small }) => (small ? '0.8rem' : '0.9rem')};
      font-weight: ${({ theme }) => theme.typography.normal.medium};
    }
  }
`

const ScoreDonut = ({ score, small = false, ...props }) => {
  const theme = useTheme()
  const data = [
    { name: 'score', value: score === null ? 0 : score },
    { name: 'max', value: score === null ? 7 : 7 - score },
  ]
  const color = getColor(theme, score)
  const colors = [color, '#f8f8f8']

  return (
    <StyledScoreDonut
      className="component--score-donut"
      small={small}
      {...props}
    >
      <ResponsiveContainer width="99%">
        <PieChart>
          <Pie
            isAnimationActive={false}
            startAngle={90}
            endAngle={-270}
            data={data}
            dataKey="value"
            nameKey="name"
            colors={colors}
            innerRadius={small ? '67.5%' : '66.5%'}
            outerRadius="80%"
            cornerRadius={2}
            stroke={0}
            margin={0}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="score" style={{ color }}>
        {score === null ? '-' : score.toFixed(1)}{' '}
        <span className="of">/ 7</span>
      </div>
    </StyledScoreDonut>
  )
}

ScoreDonut.displayName = 'ScoreDonut'

export default ScoreDonut
