import React from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { BREAKPOINTS, mq } from '@fwl/shared'

import { useStore } from '../useStore'

// import { getTrend } from '../utils';
// import Trend from './Trend';
import ScoreDonut from './ScoreDonut'

const StyledMiniPulseScoreSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;

  .area-preview {
    text-align: center;

    .area-title {
      font-size: 0.9rem;
      font-weight: ${({ theme }) => theme.typography.normal.bold};
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      word-spacing: 30000px;

      a {
        color: inherit;
      }

      ${mq(BREAKPOINTS.sm)} {
        word-spacing: normal;
      }

      ${mq(BREAKPOINTS.md)} {
        word-spacing: 30000px;
      }
    }

    .component--score-donut {
      width: 6rem;
      height: 6rem;
      margin: 0 auto;

      ${mq(BREAKPOINTS.sm)} {
        width: 7rem;
        height: 7rem;
      }
    }
  }
`

const MiniPulseScoreSummary = ({
  pulse,
  currentIteration,
  team = null,
  ...props
}) => {
  if (!pulse || !currentIteration) {
    return null
  }

  const areas = useStore((state) => state.areas)
  // const trend = getTrend(team, pulse);

  const scores =
    team === null
      ? currentIteration.scores.organization
      : currentIteration.scores.teams[team.id]

  return (
    <StyledMiniPulseScoreSummary className="" {...props}>
      {Object.entries(scores || {}).map(([areaSlug, score]) => {
        const area = areas.find(({ slug }) => slug === areaSlug)
        const to =
          team === null
            ? `/organization/pulse/areas/${area.slug}`
            : `/teams/${team.id}/pulse/areas/${area.slug}`

        return (
          <div key={area.slug} className="area-preview">
            <div className="core">
              <div className="area-title">
                <Link to={to}>{area.title}</Link>
              </div>
              {/* <Trend trend={trend.map(({ scores: areaScores }) => areaScores[area.slug])} /> */}
              <ScoreDonut small score={score} />
            </div>
          </div>
        )
      })}
    </StyledMiniPulseScoreSummary>
  )
}

MiniPulseScoreSummary.displayName = 'MiniPulseScoreSummary'

export default MiniPulseScoreSummary
