import React from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { Button, Panel } from '@fwl/shared'

import ScoreDonut from './ScoreDonut'
import Trend from './Trend'

const StyledAreaScore = styled(Panel)`
  .header {
    font-weight: ${({ theme }) => theme.typography.display.medium};
    font-family: ${({ theme }) => theme.typography.display.family};
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 0.5rem;

    .title {
      display: block;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer {
    margin-top: 0.5rem;
    text-align: center;
  }
`

const AreaScore = ({ area, score, trend, extra, to, ...props }) => (
  <StyledAreaScore withMargin={false} {...props}>
    <div className="header">
      {typeof to !== 'undefined' ? (
        <Link className="title" to={to}>
          {area.title}
        </Link>
      ) : (
        <span className="title">{area.title}</span>
      )}
    </div>
    <div className="donut">
      <ScoreDonut score={score} />
    </div>
    <div className="footer">
      {typeof trend !== 'undefined' && <Trend trend={trend} />}
      {typeof to !== 'undefined' && (
        <Button as={Link} className="title" to={to}>
          Explore
        </Button>
      )}
      {typeof extra !== 'undefined' && <div className="extra">{extra}</div>}
    </div>
  </StyledAreaScore>
)

AreaScore.displayName = 'AreaScore'

export default AreaScore
