import React from 'react'

import { NavLink } from 'react-router-dom'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import {
  BREAKPOINTS,
  mq,
  ResponsiveTable,
  ResponsiveTableCell,
  ResponsiveTableRow,
} from '@fwl/shared'

import { useStore } from '../useStore'
import { getColor } from '../utils'

const StyledPulseScoreTable = styled(ResponsiveTable)`
  th,
  ${ResponsiveTableCell} {
    text-align: center;
  }

  th {
    word-spacing: 100vw;
    text-transform: capitalize;
    opacity: 1;

    &.team-th {
      opacity: 0.5;
      text-align: left;
      width: 30%;
    }
  }

  // Add a fake ID selector as a specificity hack
  // to overwrite the styles coming from 'ResponsiveTable'
  td:not(#specifity-hack) {
    padding: 0.5rem 0;

    ${mq(BREAKPOINTS.lg)} {
      padding: 0;
      border-top: 0;
    }
  }

  .team-name {
    text-align: left;
    white-space: nowrap;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Score = styled.div`
  text-align: center;
  background: #f8f8f8;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  min-width: 50px;
  padding: 1rem;
  aspect-ratio: 1 / 1;

  ${mq(BREAKPOINTS.lg)} {
    aspect-ratio: auto;
  }
`

const getScoreStyles = (theme, score) => {
  const style = {}

  if (score === null) {
    return style
  }

  return {
    ...style,
    background: getColor(theme, score),
    color: '#FFF',
  }
}

const PulseScoreTable = ({ scores, ...props }) => {
  const { teams } = useStore((state) => state.account)
  const areas = useStore((state) => state.areas)
  const theme = useTheme()

  const teamCoreAreas = areas.filter(
    ({ isCoreArea, scope }) => scope === 'team' && isCoreArea
  )

  return (
    <StyledPulseScoreTable {...props}>
      <thead>
        <tr>
          <th className="team-th">Team</th>
          {teamCoreAreas.map((area) => (
            <th key={area.slug}>{area.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {teams.map(({ id, name }) => (
          <ResponsiveTableRow key={id}>
            <ResponsiveTableCell label="Team" className="team-name">
              <NavLink to={`/teams/${id}`}>{name}</NavLink>
            </ResponsiveTableCell>
            {teamCoreAreas.map((area) => {
              const score = id in scores ? scores[id][area.slug] : null

              return (
                <ResponsiveTableCell
                  key={`${id}-${area.id}`}
                  label={area.title}
                >
                  <Score style={getScoreStyles(theme, score)}>
                    {score === null ? '-' : score.toFixed(1)}
                  </Score>
                </ResponsiveTableCell>
              )
            })}
          </ResponsiveTableRow>
        ))}
      </tbody>
    </StyledPulseScoreTable>
  )
}

PulseScoreTable.displayName = 'PulseScoreTable'

export default PulseScoreTable
