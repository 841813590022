import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { Button, Icon, ModalLink, PageHeader, Title } from '@fwl/shared'

import Intro from '../components/Intro'
import Survey from '../components/Survey'
import SurveyHeader from '../components/SurveyHeader'
import SurveyScoreSummary from '../components/SurveyScoreSummary'
import { useStore } from '../useStore'

const SurveyDetailView = () => {
  const store = useStore()
  const surveyInstances = useStore((state) => state.surveyInstances)
  const account = useStore((state) => state.account)
  const teams = useStore((state) => state.account.teams)

  const { teamId, surveyId } = useParams()

  useEffect(() => {
    store.requestSurveyInstances()
  }, [])

  if (surveyInstances === null) {
    return 'Loading ...'
  }

  const team =
    typeof teamId === 'undefined'
      ? null
      : teams.find(({ id: tid }) => tid === parseInt(teamId, 10))
  const instance = surveyInstances.find(
    ({ id: sid }) => sid === parseInt(surveyId, 10)
  )

  const presentationURL =
    team === null
      ? `/organization/surveys/${instance.id}/presentation`
      : `/teams/${team.id}/surveys/${instance.id}/presentation`

  const breadcrumbs =
    team === null
      ? [
          { to: '/organization', label: 'Organization' },
          {
            to: `/organization/surveys/${instance.id}`,
            label: instance.survey.title,
          },
        ]
      : [
          { to: '/teams', label: 'Teams' },
          { to: `/teams/${team.id}`, label: team.name },
          {
            to: `/teams/${team.id}/surveys/${instance.id}`,
            label: instance.survey.title,
          },
        ]

  // NOTE: Temporary filter. Should probably be set on the survey itself.
  const useOverview = ['360-team', '360-organization'].includes(
    instance.survey.identifier
  )

  return (
    <>
      <PageHeader
        title={instance.survey.title}
        breadcrumbs={breadcrumbs}
        extra={
          <Button as={ModalLink} to={presentationURL} dark>
            <Icon icon="view-carousel" />
            &nbsp; Presentation mode
          </Button>
        }
      >
        <Intro>{instance.survey.description}</Intro>
      </PageHeader>
      <SurveyHeader surveyInstance={instance} />

      {useOverview && (
        <>
          <Title title="Survey areas" size="large" />
          <SurveyScoreSummary
            scores={instance.scores}
            survey={instance}
            team={team}
          />
        </>
      )}

      {!useOverview && (
        <>
          <Title title="Survey questions" size="large" />
          <Survey
            participation={instance.participation}
            questions={instance.results.questions}
            answers={instance.results.answers}
            templateContext={{
              ACCOUNT: account.name,
              NAME: 'Alex',
              TEAM: team !== null && team.name,
            }}
          />
        </>
      )}
    </>
  )
}

export default SurveyDetailView
