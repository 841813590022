import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { Button, Empty, PageHeader, Panel, Title } from '@fwl/shared'

import Intro from '../components/Intro'
import PulseScoreSummary from '../components/PulseScoreSummary'
import PulseScoreTable from '../components/PulseScoreTable'
import SurveyList from '../components/SurveyList'
import { useStore } from '../useStore'

const StyledContent = styled.div``

const HomeView = () => {
  const store = useStore()
  const pulse = useStore((state) => state.pulse)
  const currentIteration = useStore((state) => state.currentIteration)
  const surveyInstances = useStore((state) => state.surveyInstances)

  useEffect(() => {
    store.requestPulseSurveys()
    store.requestSurveyInstances()
  }, [])

  if (surveyInstances === null || pulse === null) {
    return 'Loading ...'
  }

  return (
    <StyledContent>
      <PageHeader
        title="Organization pulse"
        extra={
          <Button as={Link} to="/organization">
            View organization
          </Button>
        }
      >
        <Intro>Summary of the latest pulse directed at the organization.</Intro>
      </PageHeader>
      {pulse === false && (
        <Empty
          action={
            <Button onClick={() => store.createPulseSurvey()}>
              Launch pulse
            </Button>
          }
        >
          There&apos;s no pulse running in this organization.
        </Empty>
      )}
      {currentIteration !== null && (
        <PulseScoreSummary pulse={pulse} currentIteration={currentIteration} />
      )}

      {currentIteration !== null && (
        <>
          <Title
            title="Team pulses"
            size="xlarge"
            extra={
              <Button as={Link} to="/teams">
                View teams
              </Button>
            }
          >
            <Intro>Summary of latest pulse directed at individual teams.</Intro>
          </Title>
          <Panel style={{ padding: '1.4rem' }}>
            <PulseScoreTable scores={currentIteration.scores.teams} />
          </Panel>
        </>
      )}

      <Title
        title="Surveys"
        size="xlarge"
        extra={
          <Button as={Link} to="/launch">
            Launch survey
          </Button>
        }
      >
        <Intro>Latest surveys that have been dispatched.</Intro>
      </Title>
      <SurveyList surveys={surveyInstances} />
      {surveyInstances.length === 0 && (
        <Empty
          action={
            <Button as={Link} to="/launch">
              Launch survey
            </Button>
          }
        >
          No active surveys. Maybe it is time to start one?
        </Empty>
      )}
    </StyledContent>
  )
}

export default HomeView
