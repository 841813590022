import React from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { Button, ModalLink, Panel } from '@fwl/shared'

import { useStore } from '../useStore'

const SurveyCardContainer = styled(Panel)`
  display: flex;
  flex-direction: column;
  text-align: center;

  .footer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: auto;
  }

  .title {
    font-weight: ${({ theme }) => theme.typography.display.bold};
    font-family: ${({ theme }) => theme.typography.display.family};
    font-size: 1.2rem;
    text-align: center;
  }

  .description {
    margin: 0.5rem 0 0;
    opacity: 0.6;
  }

  .icon {
    width: 70%;
    margin: 2rem auto;

    img {
      width: 100%;
      height: auto;
    }
  }
`

const SurveyCard = ({ survey, footer, ...props }) => {
  const { badges } = useStore((state) => state.account)

  return (
    <SurveyCardContainer withMargin={false} {...props}>
      <div className="title">{survey.title}</div>
      <div className="description">{survey.body.length} questions</div>
      <div className="icon">
        {badges[survey.badge] && <img src={badges[survey.badge]} />}
      </div>
      <div className="footer">
        {typeof footer === 'undefined' ? (
          <>
            <Button as={ModalLink} to={`/launch/surveys/${survey.id}/preview`}>
              Preview
            </Button>
            <Button
              appearance="dark"
              as={Link}
              to={`/launch/surveys/${survey.id}`}
            >
              Use survey
            </Button>
          </>
        ) : (
          footer
        )}
      </div>
    </SurveyCardContainer>
  )
}

SurveyCard.displayName = 'SurveyCard'

export default SurveyCard
