import React from 'react'

import styled from '@emotion/styled'

import { Avatar } from '@fwl/shared'

const StyledTeamMemberAvatarList = styled.div`
  display: flex;
  margin-top: 0.5rem;
  align-items: center;

  > *:not(:first-child):not(.more) {
    margin-left: -1rem;
  }

  .more {
    margin-left: 0.5rem;
    font-size: 0.9rem;
    opacity: 0.5;
  }

  .empty {
    opacity: 0.5;
    font-style: italic;
  }
`

const TeamMemberAvatarList = ({ members, limit = 5, ...props }) => (
  <StyledTeamMemberAvatarList className="" {...props}>
    {members.slice(0, limit).map(({ id: memberId, email, initials, name }) => (
      <Avatar
        round
        size="small"
        key={memberId}
        label={initials}
        email={email}
        title={name}
      />
    ))}
    {members.length > limit && (
      <div className="more">+{members.length - limit} more</div>
    )}
    {members.length === 0 && <div className="empty">No members</div>}
  </StyledTeamMemberAvatarList>
)

TeamMemberAvatarList.displayName = 'TeamMemberAvatarList'

export default TeamMemberAvatarList
