import React from 'react'

import styled from '@emotion/styled'

import { Title } from '@fwl/shared'

const StyledTextQuestion = styled.div`
  .response {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 1.5rem;
    }
  }
`

const TextQuestion = ({ question, answers, ...props }) => {
  const texts = answers.reduce((acc, answer) => {
    if (question.id in answer) {
      acc.push(answer[question.id])
    }
    return acc
  }, [])

  return (
    <StyledTextQuestion className="component--render-text-question" {...props}>
      <Title title={`${texts.length} answer${texts.length === 1 ? '' : 's'}`} />
      <div className="text-question-answers">
        {texts.map((text, i) => (
          <div className="response" key={i}>
            {text}
          </div>
        ))}
      </div>
    </StyledTextQuestion>
  )
}

TextQuestion.displayName = 'TextQuestion'

export default TextQuestion
