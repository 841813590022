import React, { useEffect } from 'react'

import { Link, useParams } from 'react-router-dom'

import { Button, Icon, PageHeader, Panel, Title } from '@fwl/shared'

import AreaScore from '../components/AreaScore'
import AreaTrend from '../components/AreaTrend'
import Intro from '../components/Intro'
import ScoresHeader from '../components/ScoresHeader'
import Survey from '../components/Survey'
import { useStore } from '../useStore'
import { partitionAreas } from '../utils'

const SurveyAreaView = () => {
  const store = useStore()
  const surveyInstances = useStore((state) => state.surveyInstances)
  const account = useStore((state) => state.account)
  const areas = useStore((state) => state.areas)
  const teams = useStore((state) => state.account.teams)

  const { teamId, surveyId, areaSlug } = useParams()

  useEffect(() => {
    store.requestSurveyInstances()
  }, [])

  if (surveyInstances === null) {
    return 'Loading ...'
  }

  const team =
    typeof teamId === 'undefined'
      ? null
      : teams.find(({ id: tid }) => tid === parseInt(teamId, 10))
  const instance = surveyInstances.find(
    ({ id: sid }) => sid === parseInt(surveyId, 10)
  )
  const area = areas.find(({ slug }) => slug === areaSlug)

  const score = instance.scores[area.slug]

  const partitioned = partitionAreas(areas, instance.scores)
  const flattened = Object.values(partitioned).reduce(
    (acc, values) => [...acc, ...Object.values(values).map((v) => v[0])],
    []
  )

  const areaIndex = flattened.findIndex(({ id }) => id === area.id)
  const next = (areaIndex + 1) % flattened.length
  const prev =
    (((areaIndex - 1) % flattened.length) + flattened.length) % flattened.length

  const breadcrumbs =
    team === null
      ? [
          { to: '/organization', label: 'Organization' },
          {
            to: `/organization/surveys/${instance.id}`,
            label: instance.survey.title,
          },
          {
            to: `/organization/surveys/${instance.id}/areas/${area.slug}`,
            label: area.title,
          },
        ]
      : [
          { to: '/teams', label: 'Teams' },
          { to: `/teams/${team.id}`, label: team.name },
          {
            to: `/teams/${team.id}/surveys/${instance.id}`,
            label: instance.survey.title,
          },
          {
            to: `/teams/${team.id}/surveys/${instance.id}/areas/${area.slug}`,
            label: area.title,
          },
        ]

  return (
    <>
      <PageHeader
        title={area.title}
        breadcrumbs={breadcrumbs}
        extra={
          <>
            <Button size="slim" as={Link} to={`./${flattened[prev].slug}`}>
              <Icon icon="chevron-left" />
            </Button>
            <Button size="slim" as={Link} to={`./${flattened[next].slug}`}>
              <Icon icon="chevron-right" />
            </Button>
          </>
        }
      >
        <Intro>{area.description}</Intro>
      </PageHeader>

      <Panel>
        <ScoresHeader>
          <AreaScore area={area} score={score} />
          <AreaTrend
            area={area}
            scores={[
              {
                startedAt: instance.startedAt,
                [area.slug]: score,
              },
            ]}
            colorIndex={areaIndex}
            currentIteration={null}
          />
        </ScoresHeader>
      </Panel>

      <Title title="Survey questions" size="large" />
      <Survey
        participation={instance.participation}
        questions={instance.results.questions.filter(
          ({ area: aslug }) => aslug === area.slug
        )}
        answers={instance.results.answers}
        templateContext={{
          ACCOUNT: account.name,
          NAME: 'Alex',
          TEAM: team !== null && team.name,
        }}
      />
    </>
  )
}

export default SurveyAreaView
