import React from 'react'

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

const StyledChoiceBars = styled.div`
  .participation {
    font-size: 0.9rem;
    margin-top: 1.5rem;
    text-align: center;
    opacity: 0.7;
  }
`

const ChoiceBars = ({
  question,
  participation,
  width,
  answers: allAnswers,
  ...props
}) => {
  const { choices, id } = question
  const theme = useTheme()

  const answers = allAnswers.reduce((acc, answerSet) => {
    if (id in answerSet) {
      return [...acc, answerSet[id]]
    }
    return acc
  }, [])

  const data = choices.map((choice, choiceIndex) => ({
    name: choice,
    votes: answers.filter((answer) => Number(answer) === choiceIndex + 1)
      .length,
  }))

  const { total } = participation

  const percentage =
    participation.total === 0 ? 0 : (answers.length / participation.total) * 100

  return (
    <StyledChoiceBars className="component--choice-bars" {...props}>
      <ResponsiveContainer
        width={typeof width !== 'undefined' ? width : choices.length * 100}
        height={180}
      >
        <BarChart
          data={data}
          dataKey="votes"
          nameKey="name"
          margin={{ bottom: 60 }}
        >
          <YAxis hide domain={[0, answers.length]} />
          <XAxis
            dataKey="name"
            ticks={choices}
            interval={0}
            axisLine={false}
            tickLine={false}
            tick={(p) => (
              <Text
                {...p}
                fontSize={12}
                width={80}
                textAnchor="middle"
                verticalAnchor="start"
              >
                {p.payload.value}
              </Text>
            )}
          />
          <Tooltip
            isAnimationActive={false}
            cursor={{ fill: 'transparent' }}
            formatter={(value, name) => [
              value ? `${((value / answers.length) * 100).toFixed(2)}%` : '0%',
              name,
            ]}
            contentStyle={{
              background: '#fffe',
              fontSize: '0.9rem',
              border: 0,
              borderRadius: '0.2rem',
            }}
          />
          <Bar
            dataKey="votes"
            background={{ fill: '#f8f8f8', radius: [4, 4, 4, 4] }}
            barSize={40}
            radius={[2, 2, 2, 2]}
            fill={theme.palette.blue.base}
          />
        </BarChart>
      </ResponsiveContainer>
      <div className="participation">
        <em>n</em> = {total}, participation = {percentage.toFixed(2)}%
      </div>
    </StyledChoiceBars>
  )
}

ChoiceBars.displayName = 'ChoiceBars'

export default ChoiceBars
