import React, { useEffect, useState } from 'react'

import parseJSON from 'date-fns/parseJSON'
import { Link, useHistory, useParams } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  Alert,
  Button,
  Empty,
  Modal,
  PageHeader,
  Panel,
  PopoverMenu,
  Title,
} from '@fwl/shared'

import AreaTrends from '../components/AreaTrends'
import Intro from '../components/Intro'
import IterationDate from '../components/IterationDate'
import MemberAvatarList from '../components/MemberAvatarList'
import PulseScoreSummary from '../components/PulseScoreSummary'
import SurveyList from '../components/SurveyList'
import { useStore } from '../useStore'

const StyledContent = styled.div``

const PulseOverview = ({ currentIteration, pulse, areas, team }) => {
  if (pulse === false) {
    return (
      <Empty
        action={
          <Button as={Link} to="/launch">
            Launch pulse
          </Button>
        }
      >
        There&apos;s no pulse running in this organization yet.
      </Empty>
    )
  }

  if (pulse.iterations.length === 0) {
    return <Panel>No iterations</Panel>
  }

  if (!(team.id in currentIteration.scores.teams)) {
    return <Panel>No data for team yet.</Panel>
  }

  return (
    <>
      <Title
        title="Latest completed pulse"
        size="large"
        extra={<IterationDate />}
      >
        <Intro>
          The charts below visualize how your team performed during the last
          survey.
        </Intro>
      </Title>
      <PulseScoreSummary
        pulse={pulse}
        currentIteration={currentIteration}
        team={team}
      />
      <Title title="Pulse trend" size="large">
        <Intro>Summary of the latest pulse surveys.</Intro>
      </Title>
      <AreaTrends
        areas={areas}
        currentIteration={currentIteration}
        scores={pulse.iterations.map(
          ({ startedAt, scores, participation }) => ({
            startedAt: parseJSON(startedAt),
            ...scores.teams[team.id],
            participation: participation.teams[team.id]?.percentage,
          })
        )}
      />
    </>
  )
}

const TeamDetailView = () => {
  const store = useStore()
  const pulse = useStore((state) => state.pulse)
  const coreTeamAreas = useStore((state) => state.coreTeamAreas)
  const surveyInstances = useStore((state) => state.surveyInstances)
  const currentIteration = useStore((state) => state.currentIteration)

  const history = useHistory()

  const { id: rawId } = useParams()
  const id = parseInt(rawId, 10)
  const teams = useStore((state) => state.account.teams)
  const team = teams.find(({ id: target }) => target === id)

  const surveys = surveyInstances.filter(
    ({ team: targetTeam }) => targetTeam === team.id
  )

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const [deleteError, setDeleteError] = useState(null)

  useEffect(() => {
    store.requestPulseSurveys()
    store.requestSurveyInstances()
  }, [])

  const closeModal = () => {
    setIsDeleteConfirmationOpen(false)
    setDeleteError(null)
  }

  const deleteTeam = async () => {
    try {
      await store.deleteTeam(id)
      history.replace('/teams/')
    } catch (error) {
      console.dir(error)
      setDeleteError(error)
    }
  }

  return (
    <>
      <StyledContent>
        <PageHeader
          title={team.name}
          breadcrumbs={[
            { to: '/teams', label: 'Teams' },
            { to: `/teams/${id}`, label: team.name },
          ]}
          extra={
            <PopoverMenu>
              <Button
                as={Link}
                appearance="outline"
                to={`/teams/${id}/members`}
                block
              >
                Manage Members
              </Button>
              <Button
                onClick={() => setIsDeleteConfirmationOpen(true)}
                appearance="outline"
                block
              >
                Delete Team
              </Button>
            </PopoverMenu>
          }
        >
          <MemberAvatarList members={team.members} />
        </PageHeader>

        <PulseOverview
          currentIteration={currentIteration}
          areas={coreTeamAreas}
          team={team}
          pulse={pulse}
        />

        <Title title="Surveys" size="large" />
        <SurveyList path={`/teams/${team.id}`} surveys={surveys} />
        {surveys.length === 0 && (
          <Empty
            action={
              <Button as={Link} to="/launch">
                Launch survey
              </Button>
            }
          >
            No active surveys. Maybe it is time to start one?
          </Empty>
        )}
      </StyledContent>

      {isDeleteConfirmationOpen && (
        <Modal onClose={closeModal}>
          <Panel>
            <h2>Delete Team</h2>
            {deleteError && <Alert>An Error happened. Please try again!</Alert>}

            <p>
              Please confirm deleting <strong>{team.name}</strong>
            </p>
            <Button onClick={deleteTeam} appearance="danger">
              Confirm
            </Button>
          </Panel>
        </Modal>
      )}
    </>
  )
}

export default TeamDetailView
