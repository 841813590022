import React from 'react'

import styled from '@emotion/styled'

import { PageHeader } from '@fwl/shared'

const Question = styled.div`
  line-height: 1.6;
  margin-bottom: 2.5rem;
  max-width: 700px;
`

const SupportView = () => (
  <>
    <PageHeader title="Support" />
    <h2>FAQ</h2>
    <Question>
      <h3>What does it mean that answers are anonymous?</h3>
      <p>
        Individual survey responses are never viewable in reporting — only
        aggregate scores, (with no identification from whom they came) are
        available.
      </p>
    </Question>

    <Question>
      <h3>Why are surveys anonymous?</h3>
      <p>
        At Teamkind, we care about our survey respondent users&apos; privacy,
        confidence, and anonymity. We believe that anonymous surveys creates an
        important safe space to share our honest thoughts.
      </p>
    </Question>
  </>
)

SupportView.displayName = 'SupportView'

export default SupportView
