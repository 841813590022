import { useState } from 'react'

import styled from '@emotion/styled'

import {
  Alert,
  Avatar,
  Button,
  Icon,
  Modal,
  Panel,
  ResponsiveTableCell,
  ResponsiveTableRow,
} from '@fwl/shared'

import { useStore } from '../useStore'

const CellContent = styled.div`
  display: flex;
`

const MemberName = styled.div`
  margin-left: 0.75rem;

  .name,
  .email {
    margin: 0;
    word-break: break-all;
  }

  .name {
    font-weight: ${({ theme }) => theme.typography.normal.medium};
  }

  .email {
    font-size: 0.9rem;
    opacity: 0.5;
  }
`

const MemberSummary = ({ member }) => {
  const { id, initials, name, email, department, location, source } = member

  const deleteMember = useStore((state) => state.deleteMember)

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const [deleteError, setDeleteError] = useState(null)

  const closeModal = () => {
    setIsDeleteConfirmationOpen(false)
    setDeleteError(null)
  }

  const removeMember = async (memberId) => {
    try {
      await deleteMember(memberId)
      closeModal()
    } catch (error) {
      console.dir(error)
      setDeleteError(error)
    }
  }

  return (
    <ResponsiveTableRow>
      <ResponsiveTableCell label="Name">
        <CellContent>
          <Avatar round email={email} label={initials} />
          <MemberName>
            <p className="name">{name}</p>
            <p className="email">{email}</p>
          </MemberName>
        </CellContent>
      </ResponsiveTableCell>
      <ResponsiveTableCell label="Department">
        {department || '-'}
      </ResponsiveTableCell>
      <ResponsiveTableCell label="Location">
        {location || '-'}
      </ResponsiveTableCell>
      <ResponsiveTableCell label="Source">{source}</ResponsiveTableCell>
      <ResponsiveTableCell label="Delete Member" className="actions">
        <CellContent>
          <Button
            onClick={() => setIsDeleteConfirmationOpen(true)}
            size="slim"
            appearance="danger"
            title="Delete"
            aria-label={`Delete user ${email}`}
          >
            <Icon icon="trash" />
          </Button>

          {isDeleteConfirmationOpen && (
            <Modal onClose={closeModal}>
              <Panel>
                <h2>Delete Member</h2>
                {deleteError && (
                  <Alert>An Error happened. Please try again!</Alert>
                )}

                <p>
                  Please confirm deleting{' '}
                  <strong>
                    {name} ({email})
                  </strong>
                </p>
                <Button onClick={() => removeMember(id)} appearance="danger">
                  Confirm
                </Button>
              </Panel>
            </Modal>
          )}
        </CellContent>
      </ResponsiveTableCell>
    </ResponsiveTableRow>
  )
}

export default MemberSummary
