import React, { useState } from 'react'

import { add, formatISO } from 'date-fns'
import { Redirect, useParams } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  Button,
  ErrorMessage,
  Field,
  Input,
  PageHeader,
  Panel,
  Select,
  Title,
  useFormContext,
} from '@fwl/shared'

import Intro from '../components/Intro'
import LaunchSurveyHeader from '../components/LaunchSurveyHeader'
import { useStore } from '../useStore'

const StyledConfigureSurveyView = styled.div`
  .already-running {
    background: #f7e660;
    margin-bottom: 1.5rem;
    padding: 1.2rem;
    border-radius: 0.25rem;
  }
`

const ConfigureSurveyView = ({
  // team = null,
  ...props
}) => {
  const [launched, setLaunched] = useState(null)

  const { id: rawId } = useParams()
  const id = parseInt(rawId, 10)

  const store = useStore()
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useFormContext()

  const { teams } = useStore((state) => state.account)
  const surveys = useStore((state) => state.surveys)
  const surveyInstances = useStore((state) => state.surveyInstances)
  const survey = surveys.find(({ id: surveyId }) => surveyId === id)

  const activeSurveys = surveyInstances
    .filter(
      ({ survey: { id: surveyId }, endedAt }) =>
        surveyId === id && endedAt === null
    )
    .map(({ survey: { id: sid } }) => sid)

  const activeInTeams = surveyInstances
    .filter(
      ({ survey: { id: surveyId }, endedAt, team }) =>
        surveyId === id && endedAt === null && team !== null
    )
    .map(({ team }) => team)

  const startSurvey = async (details) => {
    const instance = await store.createSurveyInstance(id, details)
    setLaunched(instance)
  }

  if (launched !== null) {
    const to =
      launched.team === null
        ? `/organization/surveys/${launched.id}`
        : `/teams/${launched.team}/surveys/${launched.id}`
    return <Redirect to={to} />
  }

  const alreadyRunning =
    (survey.scope === 'organization' && activeSurveys.includes(survey.id)) ||
    (survey.scope === 'team' && activeInTeams.length === teams.length)

  const tomorrow = add(new Date(), {
    days: 1,
  })

  const minExpiryDate = formatISO(tomorrow, { representation: 'date' })

  return (
    <StyledConfigureSurveyView className="" {...props}>
      <PageHeader
        title={survey.title}
        breadcrumbs={[
          { to: '/launch', label: 'Survey library' },
          { to: `/launch/surveys/${id}`, label: survey.title },
        ]}
      >
        <Intro>{survey.description}</Intro>
      </PageHeader>
      <LaunchSurveyHeader survey={survey} />
      <Panel>
        <Title title="Configure and launch" />
        {alreadyRunning && (
          <div className="already-running">
            {survey.scope === 'organization'
              ? 'This survey is already running in organization.'
              : 'This survey is already running in all applicable teams.'}
          </div>
        )}

        {!alreadyRunning && (
          <form onSubmit={handleSubmit(startSurvey)} disabled>
            {survey.scope === 'team' && (
              <Field label="Run survey in team">
                <Select disabled={alreadyRunning} {...register('team')}>
                  {teams.map((team) => (
                    <option
                      value={team.id}
                      key={team.id}
                      disabled={activeInTeams.includes(team.id)}
                    >
                      {team.name}
                    </option>
                  ))}
                </Select>
              </Field>
            )}
            <Field label="End survey at">
              <Input
                disabled={alreadyRunning}
                placeholder="Enter team name ..."
                type="date"
                min={minExpiryDate}
                hasError={!!errors.endsAt}
                {...register('endsAt')}
              />
              <ErrorMessage errors={errors} fieldName="endsAt" />
            </Field>
            <Button appearance="dark" disabled={alreadyRunning} type="submit">
              Start survey
            </Button>
          </form>
        )}
      </Panel>
    </StyledConfigureSurveyView>
  )
}

ConfigureSurveyView.displayName = 'ConfigureSurveyView'

export default ConfigureSurveyView
