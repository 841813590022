import React, { useEffect } from 'react'

import styled from '@emotion/styled'

import { PageHeader } from '@fwl/shared'

import MSTeamsBots from '../components/MSTeamsBotsList'
import SlackBots from '../components/SlackBotsList'
import { useStore } from '../useStore'

const StyledSettingsView = styled.div`
  section:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`

const SettingsView = ({ ...props }) => {
  const store = useStore()
  const { id } = useStore((state) => state.account)
  const slackBots = useStore((state) => state.slackBots)
  const msteamsBots = useStore((state) => state.msteamsBots)

  useEffect(() => {
    store.requestSlackBots()
    store.requestMSTeamsBots()
  }, [])

  return (
    <StyledSettingsView className="" {...props}>
      <PageHeader title="Settings" />
      <SlackBots bots={slackBots} accountId={id} />
      <MSTeamsBots bots={msteamsBots} />
    </StyledSettingsView>
  )
}

SettingsView.displayName = 'SettingsView'

export default SettingsView
