import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { Form } from '@fwl/shared'

import newTeamSchema from '../formSchemas/newTeamSchema'
import newUserSchema from '../formSchemas/newUserSchema'
import surveyConfigSchema from '../formSchemas/surveyConfigSchema'
import { useStore } from '../useStore'
import ConfigureSurveyView from '../views/ConfigureSurveyView'
import CreateMemberView from '../views/CreateMemberView'
import CreateTeamView from '../views/CreateTeamView'
import HomeView from '../views/HomeView'
import LaunchSurveyView from '../views/LaunchSurveyView'
import MembersView from '../views/MembersView'
import OrganizationView from '../views/OrganizationView'
import PresentSurveyView from '../views/PresentSurveyView'
import PreviewSurveyView from '../views/PreviewSurveyView'
import PulseAreaView from '../views/PulseAreaView'
import SettingsView from '../views/SettingsView'
import SupportView from '../views/SupportView'
import SurveyAreaView from '../views/SurveyAreaView'
import SurveyView from '../views/SurveyView'
import TeamDetailView from '../views/TeamDetailView'
import TeamMembersView from '../views/TeamMembersView'
import TeamsView from '../views/TeamsView'

const AppRouter = () => {
  const account = useStore((state) => state.account)

  if (account === null) {
    return 'Loading ...'
  }

  return (
    <>
      <Switch>
        <Route exact path="/">
          <HomeView />
        </Route>
        <Route exact path="/organization">
          <OrganizationView />
        </Route>
        <Route exact path="/organization/surveys/:surveyId">
          <SurveyView />
        </Route>
        <Route exact path="/organization/pulse/areas/:areaSlug">
          <PulseAreaView />
        </Route>
        <Route exact path="/organization/surveys/:surveyId/areas/:areaSlug">
          <SurveyAreaView />
        </Route>

        <Route exact path="/teams">
          <TeamsView />
        </Route>
        <Route exact path="/teams/:id">
          <TeamDetailView />
        </Route>
        <Route exact path="/teams/:teamId/surveys/:surveyId">
          <SurveyView />
        </Route>
        <Route exact path="/teams/:teamId/members">
          <TeamMembersView />
        </Route>
        <Route exact path="/teams/:teamId/pulse/areas/:areaSlug">
          <PulseAreaView />
        </Route>
        <Route exact path="/teams/:teamId/surveys/:surveyId/areas/:areaSlug">
          <SurveyAreaView />
        </Route>

        <Route exact path="/settings/members">
          <MembersView />
        </Route>
        <Route exact path="/settings">
          <SettingsView />
        </Route>
        <Route exact path="/support">
          <SupportView />
        </Route>
      </Switch>

      <Switch>
        <Route exact path="/settings/teams/create">
          <Form validationSchema={newTeamSchema}>
            <CreateTeamView />
          </Form>
        </Route>
        <Route exact path="/settings/members/create">
          <Form validationSchema={newUserSchema}>
            <CreateMemberView />
          </Form>
        </Route>

        <Route exact path="/launch">
          <LaunchSurveyView />
        </Route>
        <Route exact path="/launch/surveys/:id">
          <Form validationSchema={surveyConfigSchema}>
            <ConfigureSurveyView />
          </Form>
        </Route>
        <Route exact path="/launch/surveys/:id/preview">
          <PreviewSurveyView />
        </Route>
        <Route exact path="/organization/surveys/:surveyId/presentation">
          <PresentSurveyView />
        </Route>
        <Route exact path="/teams/:teamId/surveys/:surveyId/presentation">
          <PresentSurveyView />
        </Route>
      </Switch>
    </>
  )
}

export default AppRouter
