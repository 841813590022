import React from 'react'

import styled from '@emotion/styled'

import { BREAKPOINTS, mq, Panel, renderTemplateString } from '@fwl/shared'

import { useStore } from '../useStore'

import RenderQuestion from './RenderQuestion'

const SurveyOverview = styled.div`
  --QuestionResultPadding: 5rem;

  ${mq(BREAKPOINTS.lg)} {
    --QuestionResultPadding: 1.8rem;
  }
`
const QuestionResult = styled(Panel)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding-top: var(--QuestionResultPadding);

  ${mq(BREAKPOINTS.lg)} {
    flex-direction: row;
  }
`

const SurveyQuestion = styled.div`
  font-size: 1.2rem;
  line-height: 150%;
  padding-right: 1.5rem;

  &:before {
    content: '"';
  }

  &:after {
    content: '"';
  }
`

const CategoryName = styled.p`
  font-weight: ${({ theme }) => theme.typography.display.medium};
  font-family: ${({ theme }) => theme.typography.display.family};
  text-transform: capitalize;
  background: #f8f8f8;
  border-radius: 0.25rem;
  padding: 0.4rem 0.8rem;
  margin: 0;
  position: absolute;
  top: calc(var(--QuestionResultPadding) / 4);
  left: 1.8rem;
  font-size: 0.9rem;

  ${mq(BREAKPOINTS.lg)} {
    top: var(--QuestionResultPadding);
  }

  ${mq(BREAKPOINTS.xl)} {
    left: 1.2rem;
  }
`

const Survey = ({
  questions,
  answers,
  participation,
  templateContext,
  ...props
}) => {
  const areas = useStore((state) => state.areas)

  return (
    <SurveyOverview {...props}>
      {questions.map((q) => (
        <QuestionResult key={q.id}>
          <CategoryName>
            {areas.find(({ slug }) => slug === q.area).title}
          </CategoryName>
          <SurveyQuestion>
            {renderTemplateString(q.question, templateContext)}
          </SurveyQuestion>
          <RenderQuestion
            participation={participation}
            question={q}
            answers={answers}
          />
        </QuestionResult>
      ))}
    </SurveyOverview>
  )
}

Survey.displayName = 'Survey'

export default Survey
