import React from 'react'

import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  BREAKPOINTS,
  Button,
  Datapoint,
  Datapoints,
  mq,
  Panel,
  Title,
} from '@fwl/shared'

import { useStore } from '../useStore'

import MemberAvatarList from './MemberAvatarList'
import MiniPulseScoreSummary from './MiniPulseScoreSummary'

const StyledTeamList = styled.div`
  display: grid;
  gap: 2rem;

  ${mq(BREAKPOINTS.sm)} {
    grid-template-columns: repeat(auto-fill, minmax(calc(430px - 2rem), 1fr));
  }
`

const TeamList = ({ pulse, scores, currentIteration, teams, ...props }) => {
  const surveyInstances = useStore((state) => state.surveyInstances)

  return (
    <StyledTeamList className="component--team-list" {...props}>
      {teams.map((team) => {
        const active = surveyInstances.filter(
          ({ team: teamId, endedAt }) => teamId === team.id && endedAt === null
        )
        const completed = surveyInstances.filter(
          ({ team: teamId, endedAt }) => teamId === team.id && endedAt !== null
        )
        const pcts = pulse.iterations?.reduce((acc, { participation }) => {
          if (!(team.id in participation.teams)) {
            return acc
          }
          acc.push(participation.teams[team.id].percentage)
          return acc
        }, [])
        return (
          <Panel key={team.id} withMargin={false} wideOnMobile>
            <Title
              title={<Link to={`/teams/${team.id}`}>{team.name}</Link>}
              extra={
                <Button as={Link} to={`/teams/${team.id}`}>
                  Explore
                </Button>
              }
            />
            <Datapoints>
              <Datapoint label="Members">
                <MemberAvatarList members={team.members} />
              </Datapoint>
              <Datapoint label="Average participation">
                {pcts && pcts.length > 0
                  ? `${(
                      pcts.reduce((acc, v) => acc + v, 0) / pcts.length
                    ).toFixed(1)} %`
                  : '-'}
              </Datapoint>
              <Datapoint label="Active surveys">{active.length}</Datapoint>
              <Datapoint label="Completed surveys">
                {completed.length}
              </Datapoint>
            </Datapoints>
            <MiniPulseScoreSummary
              currentIteration={currentIteration}
              pulse={pulse}
              team={team}
            />
          </Panel>
        )
      })}
    </StyledTeamList>
  )
}

TeamList.displayName = 'TeamList'

export default TeamList
