import React, { useState } from 'react'

import { Button } from '@fwl/shared'

import { useStore } from '../useStore'

import BotsIntegration from './BotsIntegration'

const SlackBots = ({ bots, accountId }) => {
  const store = useStore()
  const [isSyncing, setIsSyncing] = useState(false)
  const connectURL = new URL('/api/slack/install/', API_URL)
  connectURL.searchParams.set('account_id', accountId)

  const syncIntegration = (teamId) => async () => {
    setIsSyncing(true)
    await store.syncSlackTeam(teamId)
    setIsSyncing(false)
  }

  return (
    <BotsIntegration
      title="Slack integrations"
      description="List of Slack workspaces connected to this account."
      cnnectInitiator={
        <Button as="a" href={connectURL.href} target="_blank">
          Connect
        </Button>
      }
      bots={bots}
      isSyncing={isSyncing}
      syncIntegration={syncIntegration}
    />
  )
}

export default SlackBots
