import React from 'react'

import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import parseJSON from 'date-fns/parseJSON'

import styled from '@emotion/styled'

import { useStore } from '../useStore'

const StyledIterationDate = styled.div`
  opacity: 0.6;
`

const IterationDate = ({ ...props }) => {
  const currentIteration = useStore((state) => state.currentIteration)

  if (currentIteration === null) {
    return null
  }

  const start = parseJSON(currentIteration.startedAt)

  return (
    <StyledIterationDate className="" {...props}>
      {format(start, 'LLL d')} - {format(addDays(start, 14 - 1), 'LLL d')}
    </StyledIterationDate>
  )
}

IterationDate.displayName = 'IterationDate'

export default IterationDate
