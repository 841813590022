import React from 'react'

import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isEqual from 'date-fns/isEqual'
import parseJSON from 'date-fns/parseJSON'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import styled from '@emotion/styled'

const colors = ['#d41d64', '#ebb525', '#19816c', '#4395de']

const StyledAreaTrend = styled.div`
  min-width: 0;
`

const AreaTrend = ({
  scores,
  area,
  currentIteration = null,
  colorIndex = null,
  ...props
}) => {
  const reversedScores = [...scores].reverse()
  const lastCompletedAt =
    currentIteration === null ? null : parseJSON(currentIteration.startedAt)

  const data = reversedScores.map(({ startedAt, ...rest }) => {
    const after =
      lastCompletedAt === null ||
      isEqual(startedAt, lastCompletedAt) ||
      isAfter(startedAt, lastCompletedAt)
    const before =
      lastCompletedAt === null || !isAfter(startedAt, lastCompletedAt)

    return {
      startedAt,
      ...rest,
      [area.slug]: before ? rest[area.slug] : null,
      [`${area.slug}-after`]: after ? rest[area.slug] : null,
      useAfterLabel: !before,
    }
  })

  const start =
    scores.length > 0
      ? scores[scores.length - 1].startedAt.valueOf() * 0.99995
      : null
  const end = scores.length > 0 ? scores[0].startedAt.valueOf() * 1.00005 : null

  return (
    <StyledAreaTrend className="components--area-trend" {...props}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 5,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeWidth={1} vertical={false} stroke="#EEE" />
          <XAxis
            dataKey="startedAt"
            tick={{ dy: 10, fill: '#ccc', fontSize: 13 }}
            tickFormatter={(date) => format(date, 'LLL d')}
            axisLine={false}
            domain={[start, end]}
            type="number"
            tickSize={0}
          />
          <YAxis
            ticks={[1, 3, 5, 7]}
            domain={[1, 7]}
            axisLine={false}
            tickSize={0}
            tick={{ dx: -10, fill: '#ccc', fontSize: 13 }}
            label={{
              value: 'Score',
              angle: -90,
              position: 'insideLeft',
              fill: '#444444',
              fontWeight: 500,
            }}
          />
          <Tooltip
            position={{ y: 0 }}
            formatter={(value, name, { payload: { useAfterLabel } }) => {
              const matchesAfter = name.match(/-after$/) !== null
              if (matchesAfter && !useAfterLabel) {
                return [null, null]
              }

              return [value.toFixed(1), area.title]
            }}
            labelFormatter={(value) =>
              currentIteration === null
                ? format(value, 'LLL d')
                : `${format(value, 'LLL d')} - ${format(
                    addDays(value, 13),
                    'LLL d'
                  )}`
            }
          />
          <Line
            isAnimationActive={false}
            key={area.slug}
            dot={{
              stroke: colors[colorIndex % colors.length || 0],
              strokeWidth: 2,
            }}
            dataKey={area.slug}
            stroke={colors[colorIndex % colors.length || 0]}
            strokeWidth={2}
            type="monotone"
          />
          <Line
            isAnimationActive={false}
            key={`${area.slug}-after`}
            dot={{
              stroke: colors[colorIndex % colors.length || 0],
              strokeWidth: 2,
              strokeDasharray: '',
            }}
            dataKey={`${area.slug}-after`}
            stroke={colors[colorIndex % colors.length || 0]}
            strokeWidth={2}
            strokeDasharray="3 3"
            type="monotone"
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledAreaTrend>
  )
}

AreaTrend.displayName = 'AreaTrend'

export default AreaTrend
