import React from 'react'

import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isEqual from 'date-fns/isEqual'
import parseJSON from 'date-fns/parseJSON'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import styled from '@emotion/styled'

import { BREAKPOINTS, isNil, mq, Panel } from '@fwl/shared'

const colors = ['#d41d64', '#ebb525', '#19816c', '#4395de']

const AreaTrendsContainer = styled(Panel)`
  .header {
    margin-bottom: 2rem;
  }

  .footer {
    margin-top: 1.5rem;
  }

  .props {
    display: grid;
    grid-gap: 2rem;

    ${mq(BREAKPOINTS.md)} {
      grid-template-columns: repeat(1, 1fr);
    }

    ${mq(BREAKPOINTS.lg)} {
      grid-template-columns: repeat(3, 1fr);
    }

    .prop {
      .value {
        font-size: 1.4rem;
        font-weight: ${({ theme }) => theme.typography.normal.bold};
        font-family: ${({ theme }) => theme.typography.normal.family};
        margin-bottom: 0.2rem;
      }

      .key {
        opacity: 0.6;
      }
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    ${mq(BREAKPOINTS.sm)} {
      flex-direction: row;
    }

    ${mq(BREAKPOINTS.md)} {
      flex-direction: column;
    }

    ${mq(BREAKPOINTS.lg)} {
      flex-direction: row;
    }

    .area-legend {
      display: flex;
      align-items: center;
      background: #fafafa;
      padding: 0.4rem 0.8rem;
      border-radius: 0.25rem;

      .marker {
        svg {
          display: block;
          width: 0.8rem;
          height: 0.8rem;
        }
      }

      .series {
        margin-left: 0.3rem;
        font-size: 0.9rem;
        opacity: 0.8;
        font-weight: ${({ theme }) => theme.typography.normal.medium};
      }
    }
  }
`

const AreaTrends = ({ scores, areas, currentIteration, ...props }) => {
  const reversedScores = [...scores].reverse()
  const lastCompletedAt = parseJSON(currentIteration.startedAt)

  const data = reversedScores.map(({ startedAt, ...rest }) => {
    const after =
      isEqual(startedAt, lastCompletedAt) || isAfter(startedAt, lastCompletedAt)
    const before = !isAfter(startedAt, lastCompletedAt)

    return areas.reduce(
      (acc, area) => ({
        ...acc,
        [area.slug]: before ? rest[area.slug] : null,
        [`${area.slug}-after`]: after ? rest[area.slug] : null,
        useAfterLabel: !before,
      }),
      { startedAt, ...rest }
    )
  })

  const start =
    scores.length > 0
      ? scores[scores.length - 1].startedAt.valueOf() * 0.99995
      : null
  const end = scores.length > 0 ? scores[0].startedAt.valueOf() * 1.00005 : null

  const pcts = scores
    .map(({ participation }) => participation)
    .filter((value) => !isNil(value))

  return (
    <AreaTrendsContainer wideOnMobile {...props}>
      <div className="header">
        <div className="props">
          <div className="prop">
            <div className="value">
              {format(scores[scores.length - 1].startedAt, 'LLL d')}
            </div>
            <div className="key">Running since</div>
          </div>
          <div className="prop">
            <div className="value">{scores.length}</div>
            <div className="key">Number of pulses</div>
          </div>
          <div className="prop">
            <div className="value">
              {pcts.length === 0
                ? '-'
                : `${(
                    pcts.reduce((acc, v) => acc + v, 0) / pcts.length
                  ).toFixed(1)} %`}
            </div>
            <div className="key">Average participation</div>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={data}
          margin={{
            top: 10,
            right: 15,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeWidth={1} vertical={false} stroke="#EEE" />
          <XAxis
            dataKey="startedAt"
            tick={{ dy: 10, fill: '#ccc', fontSize: 13 }}
            tickFormatter={(date) => format(date, 'LLL d')}
            domain={[start, end]}
            type="number"
            axisLine={false}
            tickSize={0}
          />
          <YAxis
            yAxisId="scores"
            ticks={[1, 3, 5, 7]}
            domain={[1, 7]}
            axisLine={false}
            tickSize={0}
            tick={{ dx: -10, fill: '#ccc', fontSize: 13 }}
            label={{
              value: 'Score',
              angle: -90,
              style: { textAnchor: 'middle' },
              position: 'insideLeft',
              fill: '#444444',
              fontWeight: 500,
            }}
          />
          <YAxis
            yAxisId="participation"
            orientation="right"
            domain={[0, 100]}
            axisLine={false}
            tickSize={0}
            tickFormatter={(tick) => `${tick} %`}
            tick={{ dx: 10, fill: '#ccc', fontSize: 13 }}
            label={{
              value: 'Participation',
              dx: 10,
              angle: -90,
              style: { textAnchor: 'middle' },
              position: 'insideRight',
              fill: '#444444',
              fontWeight: 500,
            }}
          />
          <Tooltip
            position={{ y: 0 }}
            formatter={(value, name, { payload: { useAfterLabel } }) => {
              if (name === 'participation') {
                // return [null, null];
                return [`${value.toFixed(1)}%`, 'Participation']
              }

              const matchesAfter = name.match(/-after$/) !== null
              if (matchesAfter && !useAfterLabel) {
                return [null, null]
              }

              const { title: areaTitle } = areas.find(
                ({ slug }) => slug === name.replace(/-after$/, '')
              )
              return [value.toFixed(1), areaTitle]
            }}
            labelFormatter={(value) =>
              `${format(value, 'LLL d')} - ${format(
                addDays(value, 13),
                'LLL d'
              )}`
            }
          />
          <Bar
            dataKey="participation"
            barSize={20}
            // fill={`${colors[2]}33`}
            fill="#666666"
            fillOpacity={0.1}
            yAxisId="participation"
            radius={3}
          />
          {areas.map((area, i) => (
            <React.Fragment key={area.slug}>
              <Line
                yAxisId="scores"
                isAnimationActive={false}
                dot={{
                  stroke: colors[i % colors.length],
                  strokeWidth: 2,
                }}
                dataKey={area.slug}
                stroke={colors[i % colors.length]}
                strokeWidth={2}
                type="monotone"
              />
              <Line
                yAxisId="scores"
                isAnimationActive={false}
                dot={{
                  stroke: colors[i % colors.length],
                  strokeWidth: 2,
                  strokeDasharray: '',
                }}
                dataKey={`${area.slug}-after`}
                stroke={colors[i % colors.length]}
                strokeWidth={2}
                strokeDasharray="3 3"
                type="monotone"
              />
            </React.Fragment>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
      <div className="footer">
        <div className="legend">
          {areas.map((area, i) => (
            <div key={i} className="area-legend">
              <span className="marker">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke={colors[i % colors.length]}
                    strokeWidth="20"
                    fill="none"
                  />
                </svg>
              </span>
              <span className="series">{area.title}</span>
            </div>
          ))}
        </div>
      </div>
    </AreaTrendsContainer>
  )
}

AreaTrends.displayName = 'AreaTrends'

export default AreaTrends
