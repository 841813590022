import React from 'react'

import {
  Button,
  Empty,
  ModalLink,
  PageHeader,
  Panel,
  ResponsiveTable,
} from '@fwl/shared'

import MemberSummary from '../components/MemberSummary'
import { useStore } from '../useStore'

const MembersView = () => {
  const { members } = useStore((store) => store.account)

  return (
    <>
      <PageHeader
        title="Members"
        extra={
          <Button as={ModalLink} to="/settings/members/create">
            Add member
          </Button>
        }
      />
      {members.length === 0 ? (
        <Empty
          action={
            <Button as={ModalLink} to="/settings/members/create">
              Add member
            </Button>
          }
        >
          There are no members in this organization.
        </Empty>
      ) : (
        <Panel style={{ paddingBottom: '0.6rem' }}>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Department</th>
                <th>Location</th>
                <th>Source</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <MemberSummary key={member.id} member={member} />
              ))}
            </tbody>
          </ResponsiveTable>
        </Panel>
      )}
    </>
  )
}

export default MembersView
