import React from 'react'

import parseJSON from 'date-fns/parseJSON'
import { Link, useParams } from 'react-router-dom'

import styled from '@emotion/styled'

import { Button, Icon, PageHeader, Panel } from '@fwl/shared'

import AreaScore from '../components/AreaScore'
import AreaTrend from '../components/AreaTrend'
import Intro from '../components/Intro'
import ScoresHeader from '../components/ScoresHeader'
import Survey from '../components/Survey'
import { useStore } from '../useStore'

const StyledPulseAreaView = styled.div`
  .area-navigation {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }
`

const PulseAreaView = ({ ...props }) => {
  const account = useStore((state) => state.account)
  const teams = useStore((state) => state.account.teams)
  const areas = useStore((state) => state.areas)
  const currentIteration = useStore((state) => state.currentIteration)
  const pulse = useStore((state) => state.pulse)

  const { teamId, areaSlug } = useParams()

  if (pulse === false || pulse.iterations.length === 0) {
    return 'No data'
  }

  const area = areas.find(({ slug }) => slug === areaSlug)
  const team =
    typeof teamId === 'undefined'
      ? null
      : teams.find(({ id: tid }) => tid === parseInt(teamId, 10))

  const pulseAreas = areas.filter(
    ({ isCoreArea, scope }) =>
      isCoreArea === true && scope === (team === null ? 'organization' : 'team')
  )
  const areaIndex = pulseAreas.findIndex(({ id }) => id === area.id)

  const next = (areaIndex + 1) % pulseAreas.length
  const prev =
    (((areaIndex - 1) % pulseAreas.length) + pulseAreas.length) %
    pulseAreas.length

  const breadcrumbs =
    team === null
      ? [
          { to: '/organization', label: 'Organization' },
          { to: `/organization/pulse/areas/${area.slug}`, label: area.title },
        ]
      : [
          { to: '/teams', label: 'Teams' },
          { to: `/teams/${team.id}`, label: team.name },
          {
            to: `/teams/${team.id}/pulse/areas/${area.slug}`,
            label: area.title,
          },
        ]

  const score =
    team === null
      ? currentIteration.scores.organization[area.slug]
      : currentIteration.scores.teams[team.id][area.slug]

  const areaScores = pulse.iterations.map(({ startedAt, scores }) => {
    const filteredScores =
      team === null
        ? scores.organization[area.slug]
        : scores.teams[team.id][area.slug]

    return {
      startedAt: parseJSON(startedAt),
      [area.slug]: filteredScores,
    }
  }, [])

  const participation =
    team === null
      ? currentIteration.participation.organization
      : currentIteration.participation.teams[team.id]

  const allQuestions =
    team === null
      ? currentIteration.results.organization.questions
      : currentIteration.results.teams.questions
  const questions = allQuestions.filter(
    ({ area: aslug }) => aslug === area.slug
  )
  const answers =
    team === null
      ? currentIteration.results.organization.answers
      : currentIteration.results.teams.answers[team.id]

  return (
    <StyledPulseAreaView className="" {...props}>
      <PageHeader
        title={area.title}
        breadcrumbs={breadcrumbs}
        extra={
          <>
            <Button size="slim" as={Link} to={`./${pulseAreas[prev].slug}`}>
              <Icon icon="chevron-left" />
            </Button>
            <Button size="slim" as={Link} to={`./${pulseAreas[next].slug}`}>
              <Icon icon="chevron-right" />
            </Button>
          </>
        }
      >
        {area.description !== '' && <Intro>{area.description}</Intro>}
      </PageHeader>
      <div className="area-navigation">
        <Button as={Link} to={`./${pulseAreas[prev].slug}`}>
          <Icon icon="chevron-left" />
          {pulseAreas[prev].title}
        </Button>
        <Button as={Link} to={`./${pulseAreas[next].slug}`}>
          {pulseAreas[next].title}
          <Icon icon="chevron-right" />
        </Button>
      </div>
      <ScoresHeader wideOnMobile>
        <AreaScore
          area={area}
          score={score}
          extra={
            <div style={{ color: '#999', fontSize: '0.9rem' }}>
              Last completed pulse
            </div>
          }
        />
        <AreaTrend
          area={area}
          scores={areaScores}
          colorIndex={areaIndex}
          currentIteration={currentIteration}
        />
      </ScoresHeader>
      <br />
      <Survey
        participation={participation}
        questions={questions}
        answers={answers}
        templateContext={{
          ACCOUNT: account.name,
          NAME: 'Alex',
          TEAM: team !== null && team.name,
        }}
      />
    </StyledPulseAreaView>
  )
}

PulseAreaView.displayName = 'PulseAreaView'

export default PulseAreaView
