import React, { useEffect, useRef, useState } from 'react'

import { useLocation } from 'react-router-dom'

import {
  BREAKPOINTS,
  MobileHeader,
  Page,
  theme,
  useOutsideClick,
  useWindowSize,
} from '@fwl/shared'

import { useStore } from '../useStore'

import AppRouter from './AppRouter'
import Sidebar from './Sidebar'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = ({ ...props }) => {
  const store = useStore()
  const areas = useStore((state) => state.areas)
  const pulse = useStore((state) => state.pulse)
  const surveyInstances = useStore((state) => state.surveyInstances)
  const categories = useStore((state) => state.categories)
  const account = useStore((state) => state.account)
  const surveys = useStore((state) => state.surveys)

  const { width } = useWindowSize()

  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const sidebarRef = useRef()
  const isDesktop = width > theme.spacing.breakpoints[BREAKPOINTS.md]

  const closeSidebar = () => {
    if (!isDesktop && isSidebarOpen) {
      setIsSidebarOpen(false)
    }
  }

  useOutsideClick(sidebarRef, closeSidebar)

  const styleVariables = {
    [`--menu-offset`]: isSidebarOpen ? '16rem' : '0rem',
    [`--page-padding`]: isDesktop ? '2.5rem' : '1.5rem',
  }

  useEffect(() => {
    setIsSidebarOpen(width >= 768)
  }, [width])

  useEffect(() => {
    store.requestAccounts()
    store.requestAreas()
    store.requestPulseSurveys()
    store.requestSurveyInstances()
    store.requestSurveys()
  }, [])

  if (
    [account, areas, pulse, categories, surveyInstances, surveys].includes(null)
  ) {
    return 'Loading ...'
  }

  return (
    <div style={styleVariables} {...props}>
      <Sidebar
        closeSidebar={closeSidebar}
        isOpen={isSidebarOpen}
        ref={sidebarRef}
      />
      <Page
        dark
        withSidebar
        pre={
          <MobileHeader
            toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
            isOpen={isSidebarOpen}
          />
        }
      >
        <AppRouter />
        <ScrollToTop />
      </Page>
    </div>
  )
}

App.displayName = 'App'

export default App
