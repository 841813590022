import React from 'react'

import parseJSON from 'date-fns/parseJSON'
import create from 'zustand'
import createContext from 'zustand/context'

import { createClient, getUserAccount, useAuth } from '@fwl/shared'

const { Provider, useStore } = createContext()

const createStore = (client) =>
  create((set, get) => ({
    pulse: null,
    account: null,
    surveys: null,
    areas: null,
    coreTeamAreas: null,
    coreOrganizationAreas: null,
    surveyInstances: null,
    currentIteration: null,
    slackBots: null,
    msteamsBots: null,

    requestAccounts: async () => {
      const accounts = await client.getAccounts()
      const account = getUserAccount(accounts)

      set({ account })
    },

    requestPulseSurveys: async () => {
      const surveys = await client.getPulseSurveys()
      const pulse = surveys.length === 0 ? false : surveys[0]
      if (pulse) {
        // Set the current iteration to the currently active iteration if
        // there's only one iteration, otherwise use the last completed
        // iteration.
        if (pulse.iterations.length === 1) {
          const currentIteration = { ...pulse.iterations[0] }
          set({ currentIteration })
        } else if (pulse.iterations.length > 1) {
          const currentIteration = { ...pulse.iterations[1] }
          set({ currentIteration })
        }
      }
      set({ pulse })
    },
    requestAreas: async () => {
      const areas = await client.getAreas()
      const coreTeamAreas = areas.filter(
        ({ isCoreArea, scope }) => isCoreArea && scope === 'team'
      )
      const coreOrganizationAreas = areas.filter(
        ({ isCoreArea, scope }) => isCoreArea && scope === 'organization'
      )
      set({ areas, coreTeamAreas, coreOrganizationAreas })
    },
    createPulseSurvey: async () => {
      const created = await client.createPulseSurvey({
        account: get().account.id,
      })
      get().requestPulseSurveys()
      return created
    },

    requestSurveys: async () => {
      const surveys = await client.getSurveys()
      set({ surveys })
    },
    requestSurveyInstances: async () => {
      const surveyInstances = await client.getSurveyInstances()
      set({
        surveyInstances: surveyInstances.map(
          ({ startedAt, endedAt, endsAt, ...rest }) => ({
            startedAt: parseJSON(startedAt),
            endedAt: endedAt === null ? null : parseJSON(endedAt),
            endsAt: endsAt === null ? null : parseJSON(endsAt),
            ...rest,
          })
        ),
      })
    },
    createSurveyInstance: async (surveyId, data) => {
      const instance = await client.createSurveyInstance({
        ...data,
        surveyId,
        endsAt: data.endsAt ? `${data.endsAt}T00:00` : null,
        account: get().account.id,
      })
      await get().requestSurveyInstances()
      return instance
    },
    stopSurveyInstance: async (id) => {
      await client.stopSurveyInstance(id)
      get().requestSurveyInstances()
    },

    createMember: async (member) => {
      const created = await client.createMember({
        ...member,
        account: get().account.id,
      })
      get().requestAccounts()
      return created
    },
    deleteMember: async (memberId) => {
      const deleted = await client.deleteMember(memberId)
      get().requestAccounts()
      return deleted
    },

    createTeam: async (team) => {
      const created = await client.createTeam({
        ...team,
        account: get().account.id,
      })
      get().requestAccounts()
      return created
    },
    deleteTeam: async (teamId) => {
      await client.deleteTeam(teamId)
      get().requestAccounts()
    },
    addMemberToTeam: async (teamId, memberId) => {
      const created = await client.addMemberToTeam(teamId, memberId)
      get().requestAccounts()
      return created
    },
    removeMemberFromTeam: async (teamId, memberId) => {
      const updated = await client.removeMemberFromTeam(teamId, memberId)
      get().requestAccounts()
      return updated
    },

    requestSlackBots: async () => {
      const slackBots = await client.getSlackBots(get().account.id)
      set({ slackBots })
    },

    syncSlackTeam: async (teamId) => {
      await client.syncSlackTeam(get().account.id, teamId)
      get().requestAccounts()
    },

    requestMSTeamsBots: async () => {
      const msteamsBots = await client.getMSTeamsBots(get().account.id)
      set({ msteamsBots })
    },

    syncMSTeamsTeam: async (teamId) => {
      await client.syncMSTeamsTeam(get().account.id, teamId)
      get().requestAccounts()
    },
  }))

const StoreProvider = ({ children, baseURL }) => {
  const { token, logOut } = useAuth()
  const client = createClient({ token, baseURL, logOut })

  return <Provider createStore={() => createStore(client)}>{children}</Provider>
}

export { StoreProvider, useStore }
