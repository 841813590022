const getColor = (theme, score) => {
  if (score === null) {
    return theme.palette.gray.base
  }

  const index = Math.round(
    ((theme.scale.length - 1) * (score - 1)) / (7 - 1) + 1
  )
  return theme.scale[index - 1]
}

const getTrend = (team, pulse) =>
  team === null
    ? pulse.iterations.map(
        ({
          id,
          scores: { organization: scores },
          participation: { organization: participation },
        }) => ({ id, scores, participation })
      )
    : pulse.iterations.map(
        ({
          id,
          scores: {
            teams: { [team.id]: scores },
          },
          participation: {
            teams: { [team.id]: participation },
          },
        }) => ({ id, scores, participation })
      )

const partitionAreas = (areas, scores) =>
  // TODO: Sort by parent name and name.
  Object.entries(scores).reduce((acc, [areaSlug, score]) => {
    const area = areas.find(({ slug }) => slug === areaSlug)
    if (!(area.parent in acc)) {
      acc[area.parent] = []
    }
    acc[area.parent].push([area, score])
    return acc
  }, {})

export { getColor, getTrend, partitionAreas }
