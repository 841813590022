import React, { useState } from 'react'

import styled from '@emotion/styled'

import {
  BREAKPOINTS,
  Button,
  Field,
  Icon,
  mq,
  Panel,
  Select,
  Title,
} from '@fwl/shared'

import { useStore } from '../useStore'

import Intro from './Intro'

const StyledTeamMembers = styled.div`
  .add-member {
    .component--field .children {
      display: flex;

      .component--select {
        margin-right: 0.5rem;
      }
    }
  }
`

const DeleteButton = styled(Button)`
  margin-left: 0.5rem;

  ${mq(BREAKPOINTS.lg)} {
    opacity: 0;
  }
`

const Member = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #f2f2f2;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    ${DeleteButton} {
      opacity: 1;
    }
  }
`

const TeamMembers = ({ team }) => {
  const store = useStore()
  const [memberToAdd, setMemberToAdd] = useState('')
  const { members } = useStore((state) => state.account)
  const addedMemberIds = team.members.map(({ id: memberId }) => memberId)
  const availableMembers = members.filter(
    ({ id: memberId }) => !addedMemberIds.includes(memberId)
  )
  const addMemberToTeam = () => {
    store.addMemberToTeam(team.id, memberToAdd)
  }
  const removeMemberFromTeam = (memberId) => {
    store.removeMemberFromTeam(team.id, memberId)
  }

  return (
    <StyledTeamMembers>
      <Title title="Add member">
        <Intro>
          A single member can belong to multiple teams simultaneously. Note that
          surveys that are team-specific will be sent once per team.
        </Intro>
      </Title>
      <Panel>
        <div className="add-member">
          <Field label="Select member to add">
            <Select
              value={memberToAdd}
              onChange={(e) => setMemberToAdd(parseInt(e.target.value, 10))}
            >
              <option value="">Select member ...</option>
              {availableMembers.map(({ id: memberId, name }) => (
                <option key={memberId} value={memberId}>
                  {name}
                </option>
              ))}
            </Select>
            <Button disabled={memberToAdd === ''} onClick={addMemberToTeam}>
              Add
            </Button>
          </Field>
        </div>
      </Panel>

      <Title title="Members">
        Members of the team &apos;{team.name}&apos;.
      </Title>
      <Panel>
        <div className="member-list">
          {team.members.map(({ id: memberId, name }) => (
            <Member key={memberId}>
              {name}
              <DeleteButton
                onClick={() => removeMemberFromTeam(memberId)}
                size="slim"
                appearance="danger"
                title="Delete"
                aria-label={`Delete user ${name}`}
              >
                <Icon icon="trash" />
              </DeleteButton>
            </Member>
          ))}
        </div>
      </Panel>
    </StyledTeamMembers>
  )
}

export default TeamMembers
