import React from 'react'

import ChoiceBars from './ChoiceBars'
import LikertBars from './LikertBars'
import TextQuestion from './TextQuestion'

const RenderQuestion = ({ question, answers, participation }) => {
  if (question.type === 'text') {
    return (
      <TextQuestion
        participation={participation}
        question={question}
        answers={answers}
      />
    )
  }

  if (question.type === 'choice') {
    return (
      <div className="choice-question">
        <ChoiceBars
          participation={participation}
          question={question}
          answers={answers}
        />
      </div>
    )
  }

  if (question.type === 'likert') {
    return (
      <div className="likert-question">
        <LikertBars
          participation={participation}
          question={question}
          answers={answers}
        />
      </div>
    )
  }

  return 'Unknown answer type.'
}

export default RenderQuestion
