import React from 'react'

import format from 'date-fns/format'

import styled from '@emotion/styled'

import { Button } from '@fwl/shared'

import { useStore } from '../useStore'

const Logo = styled.img`
  width: 6rem;
  height: auto;

  margin-bottom: 2rem;
`

const Title = styled.h2`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.typography.normal.bold};
  margin-top: 0;
`

const Date = styled.time`
  margin-bottom: 1rem;
`

const DataPoint = styled.p`
  margin-top: 0;
  margin-bottom: 0.3rem;
`
const StartPresentationCTA = styled(Button)`
  margin-top: 1.2rem;
`

const PresentationIntro = ({ instance, setPosition }) => {
  const account = useStore((state) => state.account)

  return (
    <>
      {account.logo !== null && (
        <Logo className="branding" src={account.logo} />
      )}
      <Title>{instance.survey.title}</Title>
      <Date>
        {format(instance.startedAt, 'EEE, d MMM yyyy')}
        {instance.endedAt !== null &&
          ` - ${format(instance.endedAt, 'EEE, d MMM yyyy')}`}
      </Date>
      <DataPoint>
        Respondents: {instance.participation.answered} of{' '}
        {instance.participation.total}
      </DataPoint>
      <DataPoint>
        Participation rate: {instance.participation.percentage.toFixed(1)}%
      </DataPoint>
      <StartPresentationCTA
        onClick={() => setPosition((position) => position + 1)}
      >
        Start presentation
      </StartPresentationCTA>
    </>
  )
}

export default PresentationIntro
