import React, { useEffect } from 'react'

import parseJSON from 'date-fns/parseJSON'
import { Link } from 'react-router-dom'

import { Button, Empty, PageHeader, PopoverMenu, Title } from '@fwl/shared'

import AreaTrends from '../components/AreaTrends'
import Intro from '../components/Intro'
import IterationDate from '../components/IterationDate'
import MemberAvatarList from '../components/MemberAvatarList'
import PulseScoreSummary from '../components/PulseScoreSummary'
import SurveyList from '../components/SurveyList'
import { useStore } from '../useStore'

const PulseOverview = ({ currentIteration, pulse, areas }) => {
  if (pulse === false || !currentIteration) {
    return (
      <Empty
        action={
          <Button as={Link} to="/launch">
            Launch pulse
          </Button>
        }
      >
        There&apos;s no pulse running in this organization yet.
      </Empty>
    )
  }

  return (
    <>
      <Title
        title="Latest completed pulse"
        size="large"
        extra={<IterationDate />}
      >
        <Intro>
          The charts below visualize how your organization performed during the
          last survey.
        </Intro>
      </Title>
      <PulseScoreSummary pulse={pulse} currentIteration={currentIteration} />
      <Title title="Pulse trend" size="large">
        <Intro>Summary of the latest pulse surveys.</Intro>
      </Title>
      <AreaTrends
        areas={areas}
        currentIteration={currentIteration}
        scores={pulse.iterations.map(
          ({ startedAt, scores, participation }) => ({
            startedAt: parseJSON(startedAt),
            ...scores.organization,
            participation: participation.organization.percentage,
          })
        )}
      />
    </>
  )
}

const OrganizationView = () => {
  const store = useStore()
  const pulse = useStore((state) => state.pulse)
  const { members } = useStore((state) => state.account)
  const currentIteration = useStore((state) => state.currentIteration)
  const coreOrganizationAreas = useStore((state) => state.coreOrganizationAreas)
  const surveyInstances = useStore((state) => state.surveyInstances).filter(
    ({ survey }) => survey.scope === 'organization'
  )

  useEffect(() => {
    store.requestPulseSurveys()
    store.requestSurveyInstances()
  }, [])

  return (
    <>
      <PageHeader
        title="Organization"
        extra={
          <PopoverMenu>
            <Link to="/settings/members">Manage members</Link>
          </PopoverMenu>
        }
      >
        <MemberAvatarList members={members} />
      </PageHeader>

      <PulseOverview
        currentIteration={currentIteration}
        pulse={pulse}
        areas={coreOrganizationAreas}
      />

      <Title title="Surveys" size="large" />
      <SurveyList path="/organization" surveys={surveyInstances} />
      {surveyInstances.length === 0 && (
        <Empty
          action={
            <Button as={Link} to="/launch">
              Launch survey
            </Button>
          }
        >
          No active surveys. Maybe it is time to start one?
        </Empty>
      )}
    </>
  )
}

export default OrganizationView
