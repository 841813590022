import React from 'react'

import format from 'date-fns/format'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  BREAKPOINTS,
  Button,
  Content,
  Media,
  MediaObject,
  mq,
  Panel,
  Title,
} from '@fwl/shared'

import { useStore } from '../useStore'

const Container = styled.div`
  .survey-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: ${({ theme }) => theme.spacing.normal};

    .components--panel {
      margin-bottom: 0;
    }

    ${mq(BREAKPOINTS.xl)} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

const Details = styled.div`
  margin-bottom: 1.5rem;
  opacity: 0.7;
  line-height: 150%;

  .participation {
    margin: 0.2rem 0 0.5rem;
  }
`

const statusColors = {
  completed: 'lightBlue',
  active: 'lightGreen',
}

const StyledStatus = styled.div`
  margin-left: 0.5rem;
  display: block;
  padding: 0.5rem 0.7rem;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  background: ${({ status, theme }) =>
    theme.palette[statusColors[status]].base};
  color: ${({ status, theme }) => theme.palette[statusColors[status]].contrast};
`

const SurveyList = ({ surveys, ...props }) => {
  const { badges, teams } = useStore((state) => state.account)

  return (
    <Container {...props}>
      <div className="survey-list">
        {surveys.map(
          ({
            id,
            startedAt,
            survey: { badge },
            participation,
            team,
            endedAt,
            survey: { title, scope },
          }) => {
            const url =
              team !== null
                ? `/teams/${team}/surveys/${id}`
                : `/organization/surveys/${id}`

            return (
              <MediaObject as={Panel} key={id} withMargin={false}>
                <Media>{badges[badge] && <img src={badges[badge]} />}</Media>
                <Content>
                  <Title
                    title={title}
                    extra={
                      <StyledStatus
                        status={endedAt === null ? 'active' : 'completed'}
                      >
                        {endedAt === null ? 'Active' : 'Completed'}
                      </StyledStatus>
                    }
                  />
                  <Details>
                    <div className="date">
                      Launched: {format(startedAt, 'EEE, d MMM yyyy')}
                    </div>
                    <div className="participation">
                      Participation: {participation.answered} of{' '}
                      {participation.total}
                    </div>
                    <div className="scope">
                      Scope:{' '}
                      {scope === 'organization'
                        ? 'Organization'
                        : teams.find(({ id: param }) => param === team).name}
                    </div>
                  </Details>
                  <Button as={Link} to={url}>
                    View results
                  </Button>
                </Content>
              </MediaObject>
            )
          }
        )}
      </div>
    </Container>
  )
}

SurveyList.displayName = 'SurveyList'

export default SurveyList
