import React from 'react'

import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import {
  Button,
  ErrorMessage,
  Field,
  Input,
  Modal,
  Panel,
  PanelTitle,
  useFormContext,
  useRenderLocation,
} from '@fwl/shared'

import { useStore } from '../useStore'

const StyledContent = styled.div``

const CreateTeamView = () => {
  const store = useStore()
  const history = useHistory()
  const location = useRenderLocation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const redirect = () => {
    const redirectLocation =
      location.pathname === history.location.pathname
        ? { ...location, pathname: '/' }
        : location
    history.push({ ...redirectLocation, state: { preventScroll: true } })
  }

  const createTeam = async (data) => {
    await store.createTeam(data)
    redirect()
  }

  return (
    <Modal onClose={redirect}>
      <Panel>
        <StyledContent>
          <PanelTitle title="Create team" />
          <form onSubmit={handleSubmit(createTeam)}>
            <Field label="Team name">
              <Input
                placeholder="Enter team name ..."
                hasError={!!errors.name}
                {...register('name')}
              />
              <ErrorMessage errors={errors} fieldName="name" />
            </Field>
            <Button type="submit">Add team</Button>
          </form>
        </StyledContent>
      </Panel>
    </Modal>
  )
}

export default CreateTeamView
