import React, { useMemo } from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

// import { useTheme } from '../theme';

const StyledSparkline = styled.div`
  width: 100%;
  height: 100%;

  > svg {
    width: 100%;
    height: 0;
    min-height: 100%;
  }
`

const Sparkline = ({ values = [0], baselineMin = 0, ...props }) => {
  const theme = useTheme()

  if (values.length <= 1) {
    // TODO: Figure out what to do here.
    return null
  }

  const max = Math.max.apply(null, values)
  const min = Math.min.apply(null, [...values, baselineMin])

  const width = values.length - 1
  const height = Math.abs(max - min) + 4

  const getX = (value) => (value / (values.length - 1)) * width
  const getY = (value) => height - (height * (value / height) - min)

  const color = theme.palette.blue.base

  const { stroke, fill } = useMemo(() => {
    const d = values.reduce(
      (acc, value, i) => `${acc} L${getX(i)},${getY(value)}`,
      ''
    )

    return {
      stroke: `
        M${getX(0)},${getY(values[0])}
        ${d}
      `,
      fill: `
        M${getX(0)},${getY(0)}
        ${d}
        L${getX(values.length - 1)},${getY(0)}
      `,
    }
  }, [values, baselineMin])

  return (
    <StyledSparkline className="fabric--sparkline" {...props}>
      <svg viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none">
        <path d={fill} fill={color} fillOpacity="0.5" />
        <path
          d={stroke}
          strokeLinejoin="round"
          fill="none"
          stroke={color}
          strokeWidth={2}
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </StyledSparkline>
  )
}

Sparkline.displayName = 'Sparkline'

export default Sparkline
