import React from 'react'

import { useParams } from 'react-router-dom'

import styled from '@emotion/styled'

import { PageHeader } from '@fwl/shared'

import ManageTeamMembers from '../components/ManageTeamMembers'
import { useStore } from '../useStore'

const StyledTeamMembersView = styled.div``

const TeamMembersView = ({ ...props }) => {
  const { teamId } = useParams()
  const teams = useStore((state) => state.account.teams)
  const team = teams.find(({ id: target }) => target === parseInt(teamId, 10))

  return (
    <StyledTeamMembersView className="" {...props}>
      <PageHeader
        title="Team members"
        breadcrumbs={[
          { to: '/teams', label: 'Teams' },
          { to: `/teams/${teamId}`, label: team.name },
          { to: `/teams/${teamId}/members`, label: 'Team members' },
        ]}
      />
      <ManageTeamMembers team={team} />
    </StyledTeamMembersView>
  )
}

TeamMembersView.displayName = 'TeamMembersView'

export default TeamMembersView
