import React from 'react'

import styled from '@emotion/styled'

import { Button, Empty, ModalLink, PageHeader } from '@fwl/shared'

import TeamList from '../components/TeamList'
import { useStore } from '../useStore'

const StyledContent = styled.div`
  .team-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spacing.normal};

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

const TeamsView = () => {
  const currentIteration = useStore((state) => state.currentIteration)
  const pulse = useStore((state) => state.pulse)
  const teams = useStore((state) => state.account.teams)

  return (
    <StyledContent>
      <PageHeader
        title="Teams"
        extra={
          <Button as={ModalLink} to="/settings/teams/create">
            Create team
          </Button>
        }
      />

      {!teams.length && (
        <Empty
          action={
            <Button as={ModalLink} to="/settings/teams/create">
              Create team
            </Button>
          }
        >
          There are no teams yet.
        </Empty>
      )}
      <TeamList
        teams={teams}
        pulse={pulse}
        currentIteration={currentIteration}
        scores={currentIteration !== null ? currentIteration.scores.teams : []}
      />
    </StyledContent>
  )
}

export default TeamsView
