import React, { useState } from 'react'

import styled from '@emotion/styled'

import { Button, Icon, Modal, Panel } from '@fwl/shared'

import { useStore } from '../useStore'

import BotsIntegration from './BotsIntegration'

const Actions = styled.div`
  display: flex;
  gap: 0.5rem;
`

const MSTeamsBots = ({ bots }) => {
  const store = useStore()
  const [isSyncing, setIsSyncing] = useState(false)
  const [isConnectTeamsModalOpen, setIsConnectTeamsModalOpen] = useState(false)

  const downloadURL = new URL('/static/teams-app-package.zip', API_URL)

  const syncIntegration = (teamId) => async () => {
    setIsSyncing(true)
    await store.syncMSTeamsTeam(teamId)
    setIsSyncing(false)
  }

  return (
    <BotsIntegration
      title="Microsoft Teams integrations"
      description="List of Microsoft Teams teams connected to this account."
      cnnectInitiator={
        <Button type="button" onClick={() => setIsConnectTeamsModalOpen(true)}>
          Connect
        </Button>
      }
      bots={bots}
      isSyncing={isSyncing}
      syncIntegration={syncIntegration}
    >
      {isConnectTeamsModalOpen && (
        <Modal onClose={() => setIsConnectTeamsModalOpen(false)}>
          <Panel>
            <h2>Connect Microsoft Teams</h2>
            <ol>
              <li>
                Download Microsoft Teams application package{' '}
                <Button
                  style={{ verticalAlign: 'sub' }}
                  size="slim"
                  appearance="outline"
                  as="a"
                  href={downloadURL}
                  target="_blank"
                  download
                >
                  <Icon icon="download" />
                </Button>
              </li>
              <li>
                Add Teamkind application to Microsoft Teams:{' '}
                <strong>Apps &gt; Manage your apps &gt; Upload an app</strong>
              </li>
              <li>
                Add the installed Teamkind application to the desired team
              </li>
              <li>
                Refresh the current page to see the connected team in the list
              </li>
            </ol>
            <Actions>
              <Button
                type="button"
                appearance="dark"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
              <Button
                type="button"
                onClick={() => setIsConnectTeamsModalOpen(false)}
              >
                Close
              </Button>
            </Actions>
          </Panel>
        </Modal>
      )}
    </BotsIntegration>
  )
}

export default MSTeamsBots
