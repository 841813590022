import React, { useEffect, useState } from 'react'

import cn from 'classnames'

import styled from '@emotion/styled'

import { Button, PageHeader, Title } from '@fwl/shared'

import SurveyCard from '../components/SurveyCard'
import { useStore } from '../useStore'

const StyledContent = styled.div`
  .components--page-header {
    .components--button {
      background: ${({ theme }) => theme.palette.teal.base};
      color: ${({ theme }) => theme.palette.teal.contrast};
      font-weight: ${({ theme }) => theme.typography.normal.bold};
      padding: 0.7rem 1.2rem;
      font-size: 0.95rem;
      border: 0;
    }
  }

  .tabs {
    text-align: center;
    margin: 0 auto 3rem;

    button {
      background: #fff;
      border: 0;
      font-size: 0.9rem;
      padding: 0.6rem 1rem;
      border: 1px solid #00000015;
      cursor: pointer;
      font-weight: ${({ theme }) => theme.typography.normal.medium};

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &.active {
        background: #00000005;
      }
    }
  }

  .custom-survey-card {
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .components--icon {
      width: 4rem;
      height: 4rem;
    }
  }

  .custom-survey-feature {
    background: #00000015;
    background: #ebb52525;
    background: #fff;
    border-radius: 0.25rem;
    padding: 1.8rem;
    display: flex;

    align-items: center;

    .content {
      max-width: 30rem;

      p {
        line-height: 150%;
      }

      .cta {
        margin-top: 3rem;
      }
    }

    img {
      max-width: 20rem;
      margin-left: auto;
    }
  }
`
const AvailableSurveys = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(calc(300px - 2rem), 1fr));
  margin-bottom: 2rem;
`
const LaunchSurveyView = () => {
  const [scope, setScope] = useState('organization')
  const store = useStore()
  const pulse = useStore((state) => state.pulse)
  const surveys = useStore((state) => state.surveys)
  const { badges } = useStore((state) => state.account)
  const surveyInstances = useStore((state) => state.surveyInstances)

  useEffect(() => {
    store.requestPulseSurveys()
    store.requestSurveys()
    store.requestSurveyInstances()
  }, [])

  if (surveyInstances === null || surveys === null || pulse === null) {
    return 'Loading ...'
  }

  return (
    <StyledContent>
      <PageHeader
        title="Survey library"
        extra={
          <Button onClick={() => alert('Coming soon ...')}>
            <svg
              style={{ marginRight: '0.3rem' }}
              className="components--icon"
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              viewBox="0 0 24 24"
              width="20px"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
            </svg>
            Custom survey
          </Button>
        }
      />
      <div className="tabs">
        <button
          className={cn(scope === 'organization' && 'active')}
          onClick={() => setScope('organization')}
        >
          Organization surveys
        </button>
        <button
          className={cn(scope === 'team' && 'active')}
          onClick={() => setScope('team')}
        >
          Team surveys
        </button>
      </div>
      <AvailableSurveys>
        {surveys
          .filter(({ scope: term }) => term === scope)
          .map((survey) => (
            <SurveyCard key={survey.id} survey={survey} />
          ))}
      </AvailableSurveys>

      <div className="custom-survey-feature">
        <div className="content">
          <Title size="xlarge" title="Custom surveys" />
          <p>
            Teamkind allows you to create custom surveys tailored to your
            organization&apos;s or teams&apos; specific needs.
          </p>
          <div className="cta">
            <Button appearance="dark" disabled>
              Create survey
            </Button>
            &nbsp; &nbsp;
            <em>Coming soon</em>
          </div>
        </div>
        <img src={badges['custom-survey']} />
      </div>
    </StyledContent>
  )
}

export default LaunchSurveyView
