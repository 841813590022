import React from 'react'

import { Button, Datapoint, ModalLink, Panel } from '@fwl/shared'

import { useStore } from '../useStore'

import SurveyOverview from './SurveyOverview'

const SurveyHeader = ({ survey, ...props }) => {
  const { badges } = useStore((state) => state.account)

  return (
    <SurveyOverview
      as={Panel}
      badge={badges[survey.badge] && <img src={badges[survey.badge]} />}
      {...props}
    >
      <Datapoint label="Scope">
        <p style={{ textTransform: 'capitalize' }}> {survey.scope}</p>
      </Datapoint>

      <Datapoint label="Actions">
        <Button as={ModalLink} to={`/launch/surveys/${survey.id}/preview`}>
          Preview questions
        </Button>
      </Datapoint>

      <Datapoint label="Areas covered">{survey.areas.length}</Datapoint>

      <Datapoint label="Number of questions">{survey.body.length}</Datapoint>
    </SurveyOverview>
  )
}

SurveyHeader.displayName = 'SurveyHeader'

export default SurveyHeader
