import format from 'date-fns/format'
import parseJSON from 'date-fns/parseJSON'

import styled from '@emotion/styled'

import {
  Button,
  Panel,
  ResponsiveTable,
  ResponsiveTableCell,
  ResponsiveTableRow,
  Title,
} from '@fwl/shared'

const BotsIntegrationContainer = styled.section`
  margin-bottom: 2rem;
`

const IntegrationsTable = styled(ResponsiveTable)`
  th {
    &.team-name {
      width: 70%;
    }

    &.installed-at {
      width: 30%;
    }
  }
`

const SyncButton = styled(Button)`
  margin-left: 0.5rem;
`

const BotsIntegration = ({
  title,
  description,
  cnnectInitiator,
  bots,
  isSyncing,
  syncIntegration,
  children,
}) => {
  return (
    <BotsIntegrationContainer>
      <Title title={title} size="large" extra={cnnectInitiator}>
        {description}
      </Title>

      {bots && bots.length > 0 ? (
        <Panel>
          <IntegrationsTable>
            <thead>
              <tr>
                <th className="team-name">Team</th>
                <th className="installed-at">Installed on</th>
              </tr>
            </thead>
            <tbody>
              {bots.map(({ id, teamId, teamName, installedAt }) => (
                <ResponsiveTableRow key={id}>
                  <ResponsiveTableCell label="Workspace">
                    {teamName}
                    <SyncButton
                      size="slim"
                      disabled={isSyncing}
                      type="button"
                      title="Sync teams users"
                      onClick={syncIntegration(teamId)}
                    >
                      {isSyncing ? '\u22EF' : '\u27F3'}
                    </SyncButton>
                  </ResponsiveTableCell>
                  <ResponsiveTableCell label="Installed on">
                    {format(parseJSON(installedAt), 'EEE, d MMM yyyy')}
                  </ResponsiveTableCell>
                </ResponsiveTableRow>
              ))}
            </tbody>
          </IntegrationsTable>
        </Panel>
      ) : (
        'No integrations found'
      )}

      {children}
    </BotsIntegrationContainer>
  )
}

export default BotsIntegration
