import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import {
  BREAKPOINTS,
  Button,
  Icon,
  Logo,
  Modal,
  mq,
  renderTemplateString,
} from '@fwl/shared'

import PresentationIntro from '../components/PresentationIntro'
import RenderQuestion from '../components/RenderQuestion'
import useRenderLocation from '../useRenderLocation'
import { useStore } from '../useStore'

const Frame = styled.div`
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;

  display: flex;
  flex-direction: column-reverse;
  min-height: 100vh;

  ${mq(BREAKPOINTS.md)} {
    flex-direction: row;
  }

  .primary,
  .secondary {
    display: flex;
    padding: 1rem;

    ${mq(BREAKPOINTS.md)} {
      padding: 3rem;
    }
  }

  .primary {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    .logo {
      width: 8rem;
      bottom: 3rem;
      left: 3rem;

      margin-top: auto;

      ${mq(BREAKPOINTS.md)} {
        width: 10rem;
        align-self: flex-start;
      }
    }

    img {
      width: 80%;
      height: auto;
      max-width: 30rem;
    }

    .question {
      font-size: 1.3rem;
      margin-top: auto;
      margin-bottom: 2rem;
      max-width: 30rem;
      text-align: center;
      font-weight: ${({ theme }) => theme.typography.normal.medium};

      ${mq(BREAKPOINTS.md)} {
        font-size: 2rem;
      }
    }
  }

  .secondary {
    flex-direction: column;
    flex-basis: 50%;

    .header {
      display: flex;
      align-items: center;

      ${Button} {
        margin-left: auto;
      }
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 2rem 0;
      overflow: auto;
    }

    .footer {
      display: flex;
      justify-content: space-between;
    }
  }
`

const PresentSurveyView = () => {
  const theme = useTheme()
  const requestSurveyInstances = useStore(
    (store) => store.requestSurveyInstances
  )
  const { surveyId } = useParams()
  const { badges } = useStore((state) => state.account)

  const { green, red, yellow, cyan } = theme.palette
  const colors = [green, red, yellow, cyan]

  const history = useHistory()
  const location = useRenderLocation()

  const surveyInstances = useStore((state) => state.surveyInstances)

  const [position, setPosition] = useState(-1)

  useEffect(() => {
    requestSurveyInstances()
  }, [requestSurveyInstances])

  if (surveyInstances === null) {
    return 'Loading ...'
  }

  const instance = surveyInstances.find(
    ({ id: sid }) => sid === parseInt(surveyId, 10)
  )

  const onClose = () => {
    const redirectLocation =
      location.pathname === history.location.pathname
        ? { ...location, pathname: '/' }
        : location
    history.push({ ...redirectLocation, state: { preventScroll: true } })
  }

  const { questions, answers } = instance.results
  const question = position === -1 ? null : questions[position]

  const isIntro = question === null

  return (
    <Modal onClose={onClose} fullScreen>
      <Frame>
        <div
          className="primary"
          style={{
            background: colors[position % colors.length]?.base,
            color: colors[position % colors.length]?.contrast,
          }}
        >
          {isIntro && badges[instance.survey.badge] && (
            <img src={badges[instance.survey.badge]} />
          )}

          {!isIntro && (
            <>
              <div className="question">
                {renderTemplateString(question.question)}
              </div>
              <Logo />
            </>
          )}
        </div>
        <div className="secondary">
          <div className="header">
            {!isIntro && (
              <div>
                {position + 1} / {questions.length}
              </div>
            )}
            <Button onClick={onClose}>
              <Icon icon="close" />
            </Button>
          </div>
          <div className="content">
            {isIntro && (
              <PresentationIntro
                instance={instance}
                setPosition={setPosition}
              />
            )}

            {!isIntro && (
              <RenderQuestion
                question={question}
                answers={answers}
                participation={instance.participation}
              />
            )}
          </div>
          {!isIntro && (
            <div className="footer">
              <Button
                onClick={() => setPosition(position - 1)}
                disabled={position === 0}
              >
                <Icon icon="chevron-left" />
              </Button>
              <Button
                onClick={() => setPosition(position + 1)}
                disabled={position === questions.length - 1}
              >
                <Icon icon="chevron-right" />
              </Button>
            </div>
          )}
        </div>
      </Frame>
    </Modal>
  )
}

export default PresentSurveyView
