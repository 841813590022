import React from 'react'

import styled from '@emotion/styled'

const StyledIntro = styled.div`
  margin-top: 0.5rem;
  line-height: 150%;
  opacity: 0.6;
  max-width: 50rem;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`

const Intro = ({ children, ...props }) => (
  <StyledIntro className="components--intro" {...props}>
    {children}
  </StyledIntro>
)

Intro.displayName = 'Intro'

export default Intro
