import styled from '@emotion/styled'

import { BREAKPOINTS, mq, Panel } from '@fwl/shared'

const ScoresHeader = styled(Panel)`
  display: grid;
  grid-gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;

  ${mq(BREAKPOINTS.lg)} {
    grid-template-columns: repeat(2, 1fr);
  }
`

export default ScoresHeader
