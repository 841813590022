import React, { useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import styled from '@emotion/styled'

import { Button, Modal, SurveyHeader, SurveyPreview, Title } from '@fwl/shared'

import useRenderLocation from '../useRenderLocation'
import { useStore } from '../useStore'

const StyledContent = styled.div`
  background: #fff;
  padding: 3rem;
  border-radius: 0.25rem;
  width: 100%;
  margin: 0 auto;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
`

const PreviewSurveyView = () => {
  const { id: rawId } = useParams()
  const id = parseInt(rawId, 10)

  const history = useHistory()
  const location = useRenderLocation()

  const surveys = useStore((state) => state.surveys)
  const survey = surveys.find(({ id: surveyId }) => surveyId === id)

  const [isSubmitted, setIsSubmitted] = useState(false)

  const submit = () => {
    setIsSubmitted(true)
  }

  const onClose = () => {
    const redirectLocation =
      location.pathname === history.location.pathname
        ? { ...location, pathname: '/' }
        : location
    history.push({ ...redirectLocation, state: { preventScroll: true } })
  }

  return (
    <Modal onClose={onClose} style={{ maxWidth: '30rem' }}>
      <StyledContent>
        <div className="wrapper">
          {isSubmitted && (
            <>
              <Title title="Submit thoughts" />
              <p>The survey has been completed!</p>
              <Button appearance="dark" onClick={onClose}>
                Close
              </Button>
            </>
          )}

          {!isSubmitted && (
            <SurveyPreview
              questions={survey.body}
              submit={submit}
              pre={({ stepNumber, totalSteps }) => {
                return (
                  <SurveyHeader
                    stepNumber={stepNumber}
                    total={totalSteps}
                    title={survey.title}
                  />
                )
              }}
            />
          )}
        </div>
      </StyledContent>
    </Modal>
  )
}

export default PreviewSurveyView
