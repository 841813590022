import React from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

const StyledTrend = styled.div`
  text-align: center;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typography.normal.medium};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .sign {
    margin-right: 0.4rem;

    svg {
      height: 1.4rem;
      width: 1.4rem;
    }
  }
`

const calculateDiff = (trend) => {
  if (trend.length <= 2) {
    return 0
  }

  return trend[1] - trend[2]
}

const getSign = (diff) => {
  // if (diff > 0) {
  //   return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/></svg>;
  // }

  // if (diff < 0) {
  //   return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"/></svg>;
  // }

  // return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M22 12l-4-4v3H3v2h15v3z"/></svg>;
  if (diff > 0) {
    return '▴'
  }

  if (diff < 0) {
    return '▾'
  }

  return '+/-'
}

const getColor = (theme, diff) => {
  if (diff > 0) {
    return theme.scale[4]
  }

  if (diff < 0) {
    return theme.scale[0]
  }

  return theme.palette.gray.base
}

const Trend = ({ trend, suffix, ...props }) => {
  const theme = useTheme()
  const diff = calculateDiff(trend)

  const sign = getSign(diff)
  const color = getColor(theme, diff)

  return (
    <StyledTrend className="component--trend" {...props} style={{ color }}>
      <div className="sign" style={{ color }}>
        {sign}
      </div>
      {Math.abs(diff).toFixed(1)}
    </StyledTrend>
  )
}

Trend.displayName = 'Trend'

export default Trend
