import Nope from 'nope-validator'

const newUserSchema = Nope.object().shape({
  name: Nope.string().required(),
  department: Nope.string(),
  location: Nope.string(),
  email: Nope.string().email().required(),
})

export default newUserSchema
