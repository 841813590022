import { useLocation, useRouteMatch } from 'react-router-dom'

const useRenderLocation = () => {
  const currentLocation = useLocation()
  const isModal = [
    useRouteMatch('/members/:id') !== null,
    useRouteMatch('/launch/surveys/:id/preview') !== null,
  ].some((val) => val)

  console.log('here')
  console.log(currentLocation)

  // eslint-disable-next-line no-nested-ternary
  return currentLocation.state && currentLocation.state.location
    ? currentLocation.state.location
    : isModal
    ? { pathname: '/' }
    : currentLocation
}

export default useRenderLocation
